import { Injectable } from "@angular/core";
import { FeatureType } from "./feature-type";
import { firstValueFrom } from "rxjs";
import { FEATURE_URL, SPOTIFY_URL, TWITCH_URL } from "../generic/backend-urls";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../authentication/authentication.service";
import { UserEdit } from "../user/user";

/**
 * A service for the features.
 */
@Injectable({
    providedIn: 'root'
})
export class FeatureService {

    /**
     * The constructor.
     *
     * @param http                  The http client.
     * @param authenticationService The authentication service.
     */
    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {

    }

    /**
     * Returns the promise for the logged-in features.
     *
     * @return The promise for the logged-in features.
     */
    async findLoggedIn(): Promise<Array<FeatureType>> {
        return await firstValueFrom<Array<FeatureType>>(this.http.post<Array<FeatureType>>(FEATURE_URL + "/logged-in", {}));
    }

    /**
     * Logs in the user to the feature of the provided type.
     *
     * @param featureType The feature type.
     */
    login(featureType: FeatureType): void {
        let url: string = '';
        if (FeatureType[featureType] == FeatureType.SPOTIFY) {
            url = SPOTIFY_URL;
        } else if (FeatureType[featureType] == FeatureType.TWITCH) {
            url = TWITCH_URL;
        } else {
            throw new Error("A unknown feature type was provided: " + featureType);
        }
        let user: UserEdit = this.authenticationService.getUser();
        url = url +  "/login?user_token=" + user.token + "&from_frontend=true";
        window.location.href=url;
    }

    /**
     * Logs out the user from the feature of the provided type.
     *
     * @param featureType The feature type.
     * @returns The promise from the request to logout.
     */
    async logout(featureType: FeatureType): Promise<Object> {
        let url: string = FEATURE_URL + "/logout?feature=" + featureType;
        return firstValueFrom(this.http.post(url, {}));
    }

}
