import {IdentifiedEntity} from "../generic/identified-entity";
import {AbstractKey} from "../generic/abstract-key";
import { Versionable } from "../generic/versionable";
import { Language } from "../generic/language";
import { FeatureType } from "../feature/feature-type";
import { ChannelEdit } from "../channel/channel";
import { CommandEdit, NotOwnedCommandDisplay } from "../command/command";

/**
 * The key for a user.
 */
export class UserKey extends AbstractKey implements IdentifiedUser {

}

/**
 * The versionable key for a user.
 */
export class UserVersionableKey extends UserKey implements IdentifiedVersionableUser {

  /**
   * Holds the version.
   */
  readonly version: number;

  /**
   * The constructor.
   *
   * @param id The id.
   * @param version The version.
   */
  constructor(id: number, version: number) {
    super(id);
    this.version = version;
  }

}

/**
 * An edit object for a user.
 */
export class UserEdit extends UserVersionableKey {

  /**
   * Holds the username.
   */
  username: string;

  /**
   * Holds the token.
   */
  token: string;

  /**
   * Holds the email.
   */
  email: string;

  /**
   * Holds the language.
   */
  language: Language;

  /**
   * Holds the feature types.
   */
  featureTypes: FeatureType[];

  /**
   * Holds the commands owned by this channel.
   */
  ownedCommands: CommandEdit[];

  /**
   * Holds the commands used in this channel but not owned by it.
   */
  notOwnedCommands: NotOwnedCommandDisplay[];

  /**
   * Holds the channel.
   */
  channel?: ChannelEdit;

  /**
   * The constructor.
   *
   * @param id               The id.
   * @param version          The version.
   * @param username         The username.
   * @param token            The token.
   * @param email            The email.
   * @param language         The language.
   * @param featureTypes     The feature types.
   * @param ownedCommands    The commands owned by this user.
   * @param notOwnedCommands The commands used by this user but not owned by him/her.
   * @param channel          The channel.
   */
  constructor(id: number, version: number, username: string, token: string, email: string, language: Language, featureTypes: FeatureType[], ownedCommands: CommandEdit[], notOwnedCommands: NotOwnedCommandDisplay[], channel?: ChannelEdit) {
    super(id, version);

    this.username = username;
    this.token = token;
    this.email = email;
    this.language = language;
    this.featureTypes = featureTypes;
    this.ownedCommands = ownedCommands;
    this.notOwnedCommands = notOwnedCommands;
    this.channel = channel;
  }

  /**
   * Returns a copy of the provided user.
   *
   * @returns A copy of the provided user.
   */
  public static copy(user: UserEdit): UserEdit {
    return new UserEdit(user.id, user.version, user.username, user.token, user.email, user.language, user.featureTypes, user.ownedCommands, user.notOwnedCommands, user.channel)
  }
}

/**
 * The identified entity for users.
 */
export interface IdentifiedUser extends IdentifiedEntity {

}

/**
 * The versionable identified entity for users.
 */
export interface IdentifiedVersionableUser extends IdentifiedUser, Versionable {

}
