<h1>Befehle</h1>
Das ist quasi das Herzstück von den Twitch Utils.
Hier kannst du Befehle erstellen, welche du über den Twitch Bot oder die Links benutzen kannst.<br>
<br>
<img [src]="createCommand" alt="Command creation example" style="border: 1px solid deeppink;"><br>
<br>
<span class="enumeration">1)</span> Der Name von dem Befehl.<br>
<span class="enumeration">2)</span> Der Text welcher zurückgegeben werden soll. Dabei ist es möglich eigene Parameter (&#123;1&#125;) oder counter ($&#123;a&#125;) hinzuzufügen.
Wenn du mehr Parameter benutzen willst, dann kannst du diese einfach mit &#123;2&#125;, &#123;3&#125; etc. hinzufügen<br>
<span class="enumeration">3)</span> Hier kannst du den Befehl veröffentlichen. Das heißt, dass jeder andere ihn auch benutzen kann.<br>
<span class="enumeration">4)</span> Du kannst Counter zu deinem Befehl hinzuzufügen. Diese starten zurzeit immer bei 0 und werden bei jedem ausführen von dem Befehl um 1 erhöht.<br>
<br>
Weitere geplante möglichkeiten siehst du unter <a class="default-link" routerLink="/whats-next" target="_blank">Was kommt noch?</a><br>
Wenn dir irgendwas einfällt, was du hier gerne dabei hättest, dann schreibe gerne eine E-Mail an features&#64;pinkpanth3r.de.