<h1>Profil</h1>
<table>
    <tr>
        <td>
            <b>Username:</b>
        </td>
        <td>
            {{user.username}}
        </td>
    </tr>
    <tr>
        <td>
            <b>E-Mail:</b>
        </td>
        <td>
            {{user.email}}
        </td>
    </tr>
    <tr>
        <td>
            <b>Token:</b>
        </td>
        <td>
            {{token}}
        </td>
        <td>
            <button (click)="displayToken()" *ngIf="!isTokenDisplayed">Anzeigen</button>
            <button (click)="hideToken()" *ngIf="isTokenDisplayed">Verbergen</button>
        </td>
    </tr>
    <tr>
        <td>
            <b>Sprache:</b>
        </td>
        <td>
            {{getLanguage()}}
        </td>
    </tr>
    <tr>
        <td>
            <b>Features:</b>
        </td>
        <td>
            <span *ngIf="!initializingFeatures">
                <span *ngFor="let f of user.featureTypes">
                    <img [src]="loadingFeatureImg" *ngIf="isFeatureLoading(f)" style="max-width: 20px;" alt="Loading Feature ">
                    <button *ngIf="!isFeatureLoading(f)" (click)="logInOut(f)" [ngClass]="{ 'logged-in': isLoggedIn(f), 'not-logged-in': !isLoggedIn(f) }">{{f}}</button>
                </span>
            </span>
            <img [src]="loadingFeatureImg" *ngIf="initializingFeatures" style="max-width: 20px;" alt="Loading Features">
        </td>
    </tr>
    <tr>
        <td>
            <label *ngIf="hashChannel()"><b>Kanal:</b></label>
        </td>
        <td>
            <div>
                {{getChannelName()}}
            </div>
        </td>
    </tr>
    {{failureMessage}}
</table>
