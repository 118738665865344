import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

/**
 * The component for the description of the spotify feature.
 */
@Component({
    selector: 'spotify-desc',
    standalone: true,
    imports: [RouterLink],
    templateUrl: './spotify-desc.component.html'
})
export class SpotifyDescComponent {

}