import { Directive, EventEmitter, Output} from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

/**
 * A validator for a name of a command.
 */
@Directive({
  selector: '[notForbiddenName]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NotForbiddenNameDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class NotForbiddenNameDirective implements Validator {

  /**
   * Holds an emitter for providing if the name starts with an exclamation mark.
   */
  @Output("startingExclamMark")
  beginningWithExclamationMark = new EventEmitter<boolean>();

  validate(control: AbstractControl): { [key: string]: any } | null {
    const name: string = control.value;
    if (name.startsWith("!")) {
      this.beginningWithExclamationMark.emit(true);
      return { forbiddenName: { value: control.value } };
    }
    this.beginningWithExclamationMark.emit(false);
    return null;
  }
}