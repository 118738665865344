<div id="header">
    PinkPanth3rs Twitch Utils
</div>
<div id="menu">
  <a id="{{getIdFor('/')}}" [routerLink]="''" style="margin-top: 10px;">Home</a>
  <span *ngIf="isLoggedIn()">
        <br>
        <a id="{{getIdFor('/profile')}}" [routerLink]="'profile'">Profil</a>
        <a id="{{getIdFor('/channel')}}" [routerLink]="'channel'" *ngIf="userHasChannel()">Kanal</a>
        <a id="{{getIdFor('/channel/create')}}" [routerLink]="'channel/create'" *ngIf="!userHasChannel()">Kanal Erstellen</a>
        <a id="{{getIdFor('/commands')}}" [routerLink]="'commands'">Befehle</a>
        <a id="{{getIdFor('/settings')}}" [routerLink]="'settings'">Einstellungen</a>
    </span>
    <br>
    <div class="menu-header">Features</div>
    <a id="{{getIdFor('/desc/commands')}}" [routerLink]="'desc/commands'">Befehle</a>
    <a id="{{getIdFor('/desc/spotify')}}" [routerLink]="'desc/spotify'">Spotify</a>
    <a id="{{getIdFor('/desc/bot')}}" [routerLink]="'desc/bot'">Twitch Bot</a>
    <a id="{{getIdFor('/desc/links')}}" [routerLink]="'desc/links'">Links</a>
    <a id="{{getIdFor('/whats-next')}}" [routerLink]="'whats-next'" style="margin-top: 5px;">Was kommt noch?</a>
    <br>
    <a id="{{getIdFor('/login')}}" [routerLink]="'login'" *ngIf="!isLoggedIn()">Login</a>
    <a [routerLink]="''" (click)="logout()" *ngIf="isLoggedIn()">Logout</a>
</div>
<div id="content">
    <router-outlet></router-outlet>
</div>
