import { Component } from '@angular/core';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { LoginData } from './login-data';

/**
 * The component for the login.
 */
@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.css',
})
export class LoginComponent {

    /**
     * Holds the login data.
     */
    loginData: LoginData = new LoginData();

    /**
     * The constructor.
     *
     * @param loginService The login service.
     * @param router       The router.
     */
    constructor(private loginService: LoginService, private router: Router) {

    }

    /**
     * Logs in the user with the {@link loginData login data}.
     */
    login() {
        this.loginData.message = "Einen Moment Bitte";
        this.loginService.login(this.loginData);
    }

}
