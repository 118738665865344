import { Component, Input, OnInit } from '@angular/core';
import { CommandEditService as CommandEditService } from './command-edit.service';
import { CommandEdit, CommandKey, CommandType } from '../command';
import { LOADING_GIF } from '../../generic/img-pathes';
import { CounterEdit, IdentifiedCounter } from '../../counter/counter';
import { Router } from '@angular/router';
import { FeatureType } from '../../feature/feature-type';
import { IdentifiedUser, UserKey } from '../../user/user';
import { AuthenticationService } from '../../authentication/authentication.service';
import { InternalErrorException } from '../../generic/errors';
import { NEW_COUNTER_BASE_ID } from '../../counter/edit/counter-basics';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CounterEditComponent } from '../../counter/edit/counter-edit.component';
import { NgClass, NgIf } from '@angular/common';
import { NotForbiddenNameDirective } from './not-forbidden-name-directive';


/**
 * The component for creating/editing a command.
 */
@Component({
  selector: 'edit-command',
  templateUrl: './command-edit.component.html',
  styleUrl:'../commands.css',
  standalone: true,
  imports: [ReactiveFormsModule, CounterEditComponent, NgIf, NgClass, FormsModule, NotForbiddenNameDirective]
})
export class CommandEditComponent implements OnInit {

  /**
   * Holds the gif to display when we are loading the command.
   */
  loadingCommandImg: string = LOADING_GIF;

  /**
   * The URL for the router to go to when the user clicks on the back button.
   */
  @Input('routeURL')
  routeURL: string;

  /**
   * Holds if this view was opend for creating a command.
   */
  @Input('forCreate')
  isForCreate: boolean = false;

  /**
   * Holds the id of the command to edit.
   */
  @Input('command')
  commandId: number;

  /**
   * Holds the command of the provided commandId.
   */
  providedCommand: CommandEdit;

  /**
   * Holds if an error occurred for the command.
   */
  hasError: boolean = false;

  /**
   * Holds the message to display for the error.
   */
  errorMessage: string = "";

  /**
   * Holds the feature types by its names.
   */
  featureTypeNames = new Map<string, FeatureType>

  /**
   * Holds the counters for their id.
   */
  countersById: Map<number, CounterEdit> = new Map<number, CounterEdit>();

  /**
   * Holds the command to edit.
   */
  command: CommandEdit;

  /**
   * Holds if the save button shall be disabled.
   */
  saveButtonDisabled: boolean = false;

  /**
   * Holds if a counter has been changed.
   */
  counterChanged: boolean = false;

  /**
   * Holds if the name starts with an exclamation mark.
   */
  startsWithExclamationMark: boolean = false;

  /**
   * Holds if we are loading the command.
   */
  loadingCommand: boolean = true;


  /**
   * The constructor.
   *
   * @param commandEditService    The command edit service.
   * @param router                The router.
   * @param authenticationService The authentication service.
   */
  constructor(private commandEditService: CommandEditService, private router: Router, private authenticationService: AuthenticationService) {

  }

  ngOnInit(): void {
    if (this.commandId == null) {
      if (!this.isForCreate) {
        this.commandEditService.redirectTo(null, null, false);
        return;
      }
      const owner: IdentifiedUser = this.authenticationService.getUser();
      this.command = new CommandEdit(-1,
                                     0,
                                     "",
                                     "",
                                     new Array(),
                                     CommandType.USER,
                                     new UserKey(owner.id),
                                     FeatureType.TWITCH,
                                     false);
        this.loadingCommand = false;
    } else {
      if (this.isForCreate) {
        this.commandEditService.redirectTo("command/create", this.routeURL, true);
        return;
      }
      this.commandId = Number(this.commandId);
      this.initProvidedCommandEdit()
    }
    for (let [key, value] of Object.entries(FeatureType)) {
      this.featureTypeNames.set(value, key as FeatureType);
    }
  }

  /**
   * Initializes the providedCommandEdit.
   */
  private initProvidedCommandEdit() {
    this.loadingCommand = true;
    this.commandEditService.reloadCommand(new CommandKey(this.commandId))
      .then((providedCommandEdit) => {
        this.providedCommand = providedCommandEdit;
        this.command = CommandEdit.copy(this.providedCommand);
        this.countersById.clear();
        for (let counter of this.providedCommand.counters) {
          this.countersById.set(counter.id, CounterEdit.copy(counter));
        }
        this.loadingCommand = false;
        return providedCommandEdit;
      });
  }

  /**
   * Sets if the button shall be disabled.
   *
   * @param saveButtonDisabled true if the button shall be disabled, false otherwise.
   */
  setSaveButtonDisabled(saveButtonDisabled: boolean): void {
    this.saveButtonDisabled = saveButtonDisabled;
  }

  /**
   * Sets if a counter has been change.
   *
   * @param counterChanged true if a counter has been change, false otherwise.
   */
  setCounterChanged(counterChanged: boolean): void {
    this.counterChanged = counterChanged;
  }

  /**
   * Creates the command.
   */
  save() {
    if (!this.isCommandValid()) {
       return;
    }
    const counters = new Array<CounterEdit>();
    for (let counter of this.command.counters) {
      if (this.isCreatedCounter(counter)) {
        const cnt: CounterEdit = new CounterEdit(-1, 0, counter.name, counter.value, new CommandKey(this.command.id));
        counters.push(cnt);
      } else {
        counters.push(counter);
      }
    }
    if (this.command.id == -1) {
      this.command.featureType = this.featureTypeNames.get(this.command.featureType);
    }
    this.command.counters = counters;
    this.commandEditService.save(this.command, this.routeURL, this.setWrongVersion.bind(this));
  }

  /**
   * Returns if the command is valid.
   *
   * @returns true if the command is valid, false otherwise.
   */
  private isCommandValid(): boolean {
    const failureMessage = this.commandEditService.isCommandValid(this.command);
    if (failureMessage != null) {
      this.hasError = true;
      this.errorMessage = failureMessage;
    } else {
      this.hasError = false;
      this.errorMessage = "";
    }
    return failureMessage == null;
  }

  /**
   * Sets if the command had a wrong version.
   *
   * @param wrongVersion true if the command had a wrong version, false otherwise.
   */
  private setWrongVersion(wrongVersion: boolean) {
    this.hasError = wrongVersion;
    if (wrongVersion) {
      this.errorMessage = "Der Befehl war leider von einer älteren version. Bitte versuch es erneut.";
      this.initProvidedCommandEdit();
    }
  }

  /**
   * Gets called when the public state of a command has been changed.
   *
   * @param event   The event that led to this method call.
   * @param command The command where the state was changed.
   */
  publicChanged(event: any, command: CommandEdit) {
    command.public = event.target.checked;
  }

  /**
   * Returns if the provided counter was created.
   *
   * @param counter The counter.
   * @returns true if the provided counter was created, false otherwise.
   */
  private isCreatedCounter(counter: IdentifiedCounter): boolean {
    return counter.id >= NEW_COUNTER_BASE_ID;
  }

  /**
   * Get's called when the user clicks on the back button and returns to the {@link routeURL}.
   */
  back() {
    if (this.routeURL == null) {
      throw new InternalErrorException("The back function was called while the redirectURL is null");
    }
    this.router.navigate([this.routeURL]);
  }

  /**
   * Returns if the command hast been changed.
   *
   * @returns true if the command hast been changed, false otherwise.
   */
  commandHasChanged(): boolean {
    if (this.providedCommand == null) {
      return true;
    }

    if (this.command.name != this.providedCommand.name) {
      return true;
    }
    if (this.command.text != this.providedCommand.text) {
      return true;
    }
    if (this.command.public != this.providedCommand.public) {
      return true;
    }

    if (this.counterChanged) {
      return true;
    }

    return false;
  }

  /**
   * Returns if the user has a channel.
   *
   * @returns true if the user has a channel, false otherwise.
   */
  hasChannel(): boolean {
    return this.authenticationService.getUser().channel != null
  }

  /**
   * Sets the if the name starts with an exclamation mark.
   *
   * @param startsWithExclamationMark true if the name starts with an exclamation mark, false otherwise.
   */
  setStartsWithExclamationMark(startsWithExclamationMark: boolean) {
    this.startsWithExclamationMark = startsWithExclamationMark;
  }
}
