import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';

import {AppComponent} from './app.component';
import {appRoutes, routes} from './app.routes';

import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {AuthenticationInterceptor} from './authentication/authentication.interceptor';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { ChannelComponent } from './channel/channel.component';
import { CreateChannelComponent } from './channel/create/create-channel.component';
import { CommandsComponent } from './command/commands.component';
import { CommandSearchComponent } from './command/search/command-search.component';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { NotOwnedCommandsComponent } from './command/notOwnedCommands/not-owned-commands.component';
import { CommandEditComponent } from './command/edit/command-edit.component';
import { CounterEditComponent } from './counter/edit/counter-edit.component';
import { EmojiDirective } from './generic/emoji.directive';

/**
 * The root module.
 */
@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    routes,
    CounterEditComponent,
    NotOwnedCommandsComponent,
    CommandEditComponent,
    EmojiDirective,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(appRoutes, withComponentInputBinding()),
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'de-DE'},
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ProfileComponent,
    ChannelComponent,
    CreateChannelComponent,
    CommandsComponent,
    CommandSearchComponent,
    SettingsComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

