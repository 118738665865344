<img [src]="loadingImg" *ngIf="initializingCommands" class="loading-img-centered" alt="loading">
<table *ngIf="!initializingCommands" class="command-table" style="width: 100%;">
    <tr *ngIf="fromUser">
        <td colspan="5" class="sub-th command-table-cell">
            <div class="row">
                <div class="column">Befehle von anderen</div>
                <div class="column">
                    <img (click)="goToSearch()" class="button-img" style="float: right;" alt="search command" [src]="magnifierImg">
                </div>
            </div>
        </td>   
    </tr>
    <tr *ngIf="commands.length > 0">
        <th id="first-column" class="command-table-header">Name</th>
        <th id="second-column" class="command-table-header">Text</th>
        <th class="command-table-header">Von</th>
        <th class="command-table-header"></th>
    </tr>
    <tr *ngFor="let command of commands">
        <td class="command-output command-table-cell" [ngStyle]="createCellStyle(command)">
            <div>{{command.name}}</div>
        </td>
        <td class="command-output command-table-cell" [ngStyle]="createCellStyle(command)">
            <div class="command-output" *ngIf="!disableCommand(command)">{{command.text}}</div>
            <div class="command-output" *ngIf="!fromUser && alreadyUsed(command)">Du besitzt diesen Befehl bereits</div>
            <div class="command-output" *ngIf="findMissingFeature(command); let missingFeature">Du brauchst das Feature "{{missingFeature}}" um diesen Befehl zu benutzen</div>
        </td>
        <td [ngStyle]="createCellStyle(command)" class="command-table-cell" style="padding-left: 10px;">
            <div>{{command.owner}}</div>
        </td>
        <td class="button-cell command-table-cell" [ngStyle]="createCellStyle(command)">
            <img *ngIf="!fromUser && !disableCommand(command)" (click)="addCommand(command)" class="button-img-centered" alt="add" [src]="acceptImg">
            <img *ngIf="fromUser" (click)="removeCommand(command)" class="button-img-centered" alt="remove" [src]="removeImg">
        </td>
    </tr>
</table>
