<h1>Befehle suchen</h1>
<div *ngIf="hasError" class="error-message" [innerHTML]="errorMessage">
  {{errorMessage}}
</div>
<not-owned-commands *ngIf="!forChannel" [fromUser]="false" (addRequest)="addCommand($event)"></not-owned-commands>

<table *ngIf="forChannel && missingChannelCommands.length > 0" class="command-table" style="width: 100%;">
    <tr>
      <th id="first-column" class="command-table-header">Name</th>
      <th id="second-column" class="command-table-header">Text</th>
      <th class="command-table-header"></th>
    </tr>
    <tr *ngFor='let command of missingChannelCommands'>
      <td class="command-table-cell">
        <div class="command-output">{{command.name}}</div>
      </td>
      <td class="command-table-cell">
        <div class="command-output">{{command.text}}</div>
      </td>
      <td class="button-cell command-table-cell" >
        <img (click)="addCommand(command)" class="button-img-centered" alt="add" [src]="acceptImg">
      </td>
    </tr>
</table>
<div *ngIf="forChannel && missingChannelCommands.length == 0">
  Du hast bereits alle hinzugefügt
</div>
<button *ngIf="routeURL != null" (click)="back()" style="margin-top: 15px;">Zurück</button>
