import { IdentifiedEntity } from "./identified-entity";

/**
 * Filters the provided identified from the array. It creates a new one.
 *
 * @param array      The array.
 * @param identified The identified.
 * @returns The filtered array. It creates a new one!
 */
export function filterIdentified<T extends I, I extends IdentifiedEntity>(array: Array<T>, identified: I): Array<T> {
    return array.filter(ident => ident.id != identified.id);
}

/**
 * Returns if the provided identified is inside the array.
 *
 * @param array      The array.
 * @param identified The identified.
 * @returns true if the provided identified is inside the array, false otherwise.
 */
export function containsIdentified<T extends I, I extends IdentifiedEntity>(array: Array<T>, identified: I): boolean {
    const filteredLength = filterIdentified(array, identified).length;
    return filteredLength != array.length;
}

/**
 * Returns if the provided value is inside the array.
 *
 * @param array The array.
 * @param value The value.
 * @returns true if the provided value is inside the array, false otherwise.
 */
export function arrayContains<T>(array: Array<T>, value: T): boolean {
    const filteredLength = array.filter(v => v == value).length;
    return filteredLength == 1;
}
