import { Injectable } from "@angular/core";
import { FeatureType } from "../feature/feature-type";
import { FeatureService } from "../feature/feature.service";

/**
 * A service for the profile.
 */
@Injectable({
    providedIn: 'root'
})
export class ProfileService {
        
    /**
     * The constructor.
     *
     * @param featureService The feature service.
     */
    constructor(private featureService: FeatureService) {

    }

    /**
     * Returns the promise for the logged-in features.
     *
     * @return The promise for the logged-in features.
     */
    findLoggedInFeatures(): Promise<Array<FeatureType>> {
        return this.featureService.findLoggedIn();
    }
    
    /**
     * Logs out the user from the feature of the provided type.
     *
     * @param featureType The feature type.
     * @returns The promise from the request to logout.
     */
    logout(featureType: FeatureType): Promise<Object> {
        return this.featureService.logout(featureType);
    }
    
    /**
     * Logs in the user to the feature of the provided type.
     *
     * @param featureType The feature type.
     */
    login(featureType: FeatureType): void/* Observable<Object> */ {
        this.featureService.login(featureType);
    }

}