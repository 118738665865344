import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { InternalErrorException } from './errors';

/**
 * A directive to display an emoji from unicode.
 */
@Directive({
  selector: '[emoji]',
  standalone: true,
})
export class EmojiDirective implements OnInit {

  /**
   * The name of the emoji.
   */
  @Input({alias: 'emoji', required: true}) emoji: string;

  /**
   * The constructor.
   *
   * @param el The element ref.
   */
  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.el.nativeElement.textContent += this.getEmoji(this.emoji);
  }

  /**
   * Returns the unicode for the emoji.
   *
   * @param emojiName The name of the emoji to display.
   * @returns The unicode for the emoji.
   */
  getEmoji(emojiName: string): string {
    let emoji: string;
    switch(emojiName){
      case "sweat-smile":
        emoji = '\uD83D\uDE05';
        break;
      default:
        throw new InternalErrorException("An unknow emojiName was provided: " + emojiName);
    }

    return emoji
  }
}
