import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../authentication/authentication.service';
import { COMMAND_URL } from '../../generic/backend-urls';
import { Router } from '@angular/router';
import { DefaultUserResponseHandler } from '../../user/user-response';
import { CommandEdit, IdentifiedCommand, NotOwnedCommandDisplay } from '../command';
import { firstValueFrom } from 'rxjs';
import { CounterEdit } from '../../counter/counter';
import { UserEdit } from '../../user/user';

/**
 * A service for the editting of commands. This includes the creation.
 */
@Injectable({
  providedIn: 'root'
})
export class CommandEditService {

  /**
   * The constructor.
   *
   * @param http                  The http client.
   * @param authenticationService The authentication service.
   * @param router                The router.
   */
  constructor(private http: HttpClient, private authenticationService: AuthenticationService, private router: Router) { }

  /**
   * Saves the provided command.
   *
   * @param command         The command.
   * @param setWrongVersion A setter to use if the command had a wrong version.
   */
  save(command: CommandEdit, routeURL: string, setWrongVersion: (wrongVersion: boolean) => void) {
    const loggedInUser = this.authenticationService.getUser();
    const errorMessage = "An error occurred for user " + loggedInUser.id + " while trying to create/update the command " + JSON.stringify(command);
    const responseHandler= new DefaultUserResponseHandler(this.authenticationService, errorMessage, this.router, routeURL, setWrongVersion);
    this.http.post(COMMAND_URL + "/save", command).subscribe(responseHandler);
  }

  /**
   * Returns a promise for the reloaded version of the provided command.
   *
   * @param command The command to reload.
   * @returns The promise for the reloaded command.
   */
  async reloadCommand(command: IdentifiedCommand): Promise<CommandEdit> {
    const reloaded = await firstValueFrom<CommandEdit>(this.http.post<CommandEdit>(COMMAND_URL + '/find', command));
    if (this.commandHasChanged(reloaded)) {
      this.authenticationService.reloadUser();
    }
    return reloaded;
  }

  /**
   * Checks if the provided command has been edited.
   *
   * @param command The command.
   * @returns true if the command has been changed, false otherwise.
   */
  private commandHasChanged(command: CommandEdit): boolean {
    const commands: CommandEdit[] = this.authenticationService.getUser().ownedCommands;
    for (let currentCommand of commands) {
      if (currentCommand.id == command.id) {
        if (currentCommand.version != command.version) {
          return true;
        }
        return this.counterHasChanged(currentCommand.counters, command.counters)
      }
    }
    return false;
  }

  /**
   * Returns a failure message if one occurred.
   *
   * @param toValidate The command to validate.
   * @returns A failure message if one occurred.
   */
  isCommandValid(toValidate: CommandEdit | NotOwnedCommandDisplay): string {
    const user: UserEdit = this.authenticationService.getUser();
    for(let command of user.ownedCommands) {
      if (command.id != toValidate.id && command.name == toValidate.name) {
        return "Du besitzt bereits einen Befehl mit diesem Namen";
      }
    }
    for (let command of user.notOwnedCommands) {
      if (command.name == toValidate.name) {
        return 'Du besitzt bereits einen Befehl von ' +
                command.owner +
                ' mit diesem Namen<br>' +
                '<span class="default-message">In vermutlich noch ferner Zukunft wird es möglich sein, ' +
                'einen eigenen Namen für den Befehl von jemand anders zu benutzen.</span>';
      }
    }
    return null;
  }

  /**
   * Checks if the provided counter has been edited.
   *
   * @param counter The counter.
   * @returns true if the counter has been changed, false otherwise.
   */
  private counterHasChanged(currentCounters: CounterEdit[], newCounters: CounterEdit[]) {
    if (currentCounters.length != newCounters.length) {
      return true;
    }

    let missingCounters: CounterEdit[] = JSON.parse(JSON.stringify(newCounters));
    for (let currentCounter of currentCounters) {
      for (let missingCounter of missingCounters) {
        if (currentCounter.id == missingCounter.id && currentCounter.version != missingCounter.version) {
          console.log("counter changed");
          return true;
        }
      }
      missingCounters = missingCounters.filter((counter) => counter.id != currentCounter.id);
    }

    return false;
  }

  /**
   * Redirects the user to the provided redirect URL.
   *
   * @param redirectURL The URL to redirect to.
   * @param routeUrl    The URL to provided as routeUrl.
   * @param forCreate   true if it should be redirected for creating, false otherwise.
   */
  redirectTo(redirectURL: string, routeUrl: string, forCreate: boolean) {
    let redirectTo: string;
    if (redirectURL == null) {
      redirectTo = 'commands';
    } else {
      redirectTo = redirectURL
      routeUrl = redirectURL;
    }
    let withRouteUrlParam: boolean = routeUrl!= null;
    if (withRouteUrlParam && forCreate) {
      this.router.navigate([redirectTo], { queryParams: { routeURL: routeUrl, forCreate: forCreate}});
    } else if (withRouteUrlParam) {
      this.router.navigate([redirectTo], { queryParams: { routeURL: routeUrl}});
    } else if (forCreate) {
      this.router.navigate([redirectTo], { queryParams: { forCreate: forCreate}});
    } else {
      this.router.navigate([redirectTo]);
    }
  }
}
