import { Component, OnInit } from '@angular/core';
import { CommandEdit, CommandKey, IdentifiedCommand, NotOwnedCommandDisplay } from './command';
import { CommandsService } from './commands.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { UserEdit } from '../user/user';
import { Router } from '@angular/router';
import { FeatureType } from '../feature/feature-type';
import { ADD_IMG, DELETE_IMG, EDIT_IMG, LOADING_GIF, MAGNIFIER_IMG } from '../generic/img-pathes';

/**
 * The component for the commands.
 */
@Component({
    selector: 'commands',
    templateUrl: './commands.component.html',
    styleUrl: './commands.css',
})
export class CommandsComponent implements OnInit {

    /**
     * Holds the path to the add image.
     */
    addImg: string = ADD_IMG;

    /**
     * Holds the path to the edit image.
     */
    editImg: string = EDIT_IMG;

    /**
     * Holds the path to the delete image.
     */
    deleteImg: string = DELETE_IMG;

  /**
   * Holds the gif to display when we are currently deleting.
   */
    deletingImg: string = LOADING_GIF;

    /**
     * Holds the path to the magnifier image.
     */
    magnifierImg: string = MAGNIFIER_IMG;

    /**
     * Holds the feature types by its names.
     */
    featureTypeNames = new Map<string, FeatureType>

    /**
     * Holds the commands owned by the user.
     */
    ownedCommands: CommandEdit[] = new Array<CommandEdit>();

    /**
     * Holds the commands for their id.
     */
    commandsById: Map<number, CommandEdit> = new Map<number, CommandEdit>();

    /**
     * Holds if the command had a wrong version.
     */
    wrongVersion: boolean = false;

    /**
     * Holds the commands currently deleting.
     */
    commandsToDelete: IdentifiedCommand[];

    /**
     * The constructor.
     *
     * @param authenticationService The authentication service.
     * @param commandsService       The command service.
     * @param router                The router.
     */
    constructor(private authenticationService: AuthenticationService, private commandsService: CommandsService, private router: Router) {

    }

    ngOnInit(): void {
        const ownedCommands: CommandEdit[] = this.commandsService.findOwnedCommands();
        this.initCommands(ownedCommands);
        this.authenticationService.subscribeToUserChange((user: UserEdit) => {
            this.initCommands(user.ownedCommands);
        });

        for (let [key, value] of Object.entries(FeatureType)) {
          this.featureTypeNames.set(value, key as FeatureType);
        }
    }

    /**
     * Initializes the commands and everything needed for them.
     *
     * @param currentlyOwnedCommands  The commands currently owned by the user.
     */
    initCommands(currentlyOwnedCommands: CommandEdit[]): void {
        this.commandsToDelete = [];
        this.ownedCommands = [];
        this.commandsById = new Map();
        for(let command of currentlyOwnedCommands) {
            this.ownedCommands.push(CommandEdit.copy(command));
            this.commandsById.set(command.id, command);
        }
    }

    /**
     * Creates a command.
     */
    createCommand(): void {
        this.commandsService.createCommand();
    }

    /**
     * Edits the provided command.
     *
     * @param command The command.
     */
    editCommand(command: CommandEdit): void {
        this.commandsService.editCommand(command);
    }

    /**
     * Gets called when the public state of a command has been changed.
     *
     * @param event   The event that led to this method call.
     * @param command The command where the state was changed.
     */
    publicChanged(event: any, command: CommandEdit) {
        command.public = event.target.checked;
        this.commandsService.save(command);
    }

    /**
     * Returns if the provided command is deleating at the moment.
     *
     * @param command The command.
     * @returns true if the provided command is deleating at the moment, false otherwise.
     */
    isDeletingCommand(command: CommandEdit): boolean {
        return this.commandsToDelete.filter(cmd => cmd.id == command.id).length == 1;
    }

    /**
     * Deletes the provided command.
     *
     * @param command The command.
     */
    delete(command: CommandEdit) {
        this.commandsToDelete.push(new CommandKey(command.id));
        this.commandsService.delete(command, this.setWrongVersionForDelete.bind(this));
    }

    /**
     * Sets if the command to delete had a wrong version.
     *
     * @param command The command.
     * @param wrongVersion true if the command to delete had a wrong version, false otherwise.
     */
    private setWrongVersionForDelete(command: IdentifiedCommand, wrongVersion: boolean) {
        this.wrongVersion = wrongVersion;
        if (wrongVersion) {
            this.commandsToDelete = this.commandsToDelete.filter(cmd => cmd.id != command.id);
            this.commandsService.reloadCommand(command);
        }
    }

    /**
     * Removes the provided command from the user.
     *
     * @param command The command.
     */
    removeCommand(command: NotOwnedCommandDisplay) {
        this.commandsService.removeNotOwned(command);
    }

    /**
     * Moves to the search page for the commands.
     */
    goToSearch() {
        this.router.navigate(['commands/search'], {queryParams: {routeURL: 'commands'}});
    }

    /**
     * Checks if the provided command has been edited.
     *
     * @param command The command.
     * @returns true if the command has been changed, false otherwise.
     */
    commandHasChanged(command: CommandEdit): boolean {
        if (command.id == -1) {
            return true;
        }
        const previousCommand: CommandEdit = this.commandsById.get(command.id);
        if (command.name.trim() != previousCommand.name.trim()) {
            return true;
        }
        if (command.text.trim() != previousCommand.text.trim()) {
            return true;
        }
        if (command.public != previousCommand.public) {
            return true;
        }
        return false;
    }
}
