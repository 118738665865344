import { Injectable } from '@angular/core';
import { ChannelEdit } from '../channel';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../authentication/authentication.service';
import { CHANNEL_URL } from '../../generic/backend-urls';
import { UserKey } from '../../user/user';
import { Router } from '@angular/router';
import { DefaultUserResponseHandler } from '../../user/user-response';

/**
 * A service for the creation of a channel.
 */
@Injectable({
  providedIn: 'root'
})
export class CreateChannelService {

  /**
   * The constructor.
   *
   * @param http                  The http client.
   * @param authenticationService The authentication service.
   */
  constructor(private http: HttpClient, private authenticationService: AuthenticationService, private router: Router) { }

  /**
   * Creates a channel with the provided values.
   * 
   * @param channelName The name of the channel.
   */
  save(channelName: string) {
    const loggedInUser = this.authenticationService.getUser();
    const channelToSave = new ChannelEdit(-1, 0, channelName, new Array(), new Array(), new UserKey(loggedInUser.id));
    const errorMessage = "An error occurred for user " + loggedInUser.id + " while trying to create a chanel with the name " + channelName;
    const responseHandler= new DefaultUserResponseHandler(this.authenticationService, errorMessage, this.router, 'channel');
    this.http.post(CHANNEL_URL + "/save", channelToSave).subscribe(responseHandler);
  }

}