
/**
 * The data used for the login.
 */
export class LoginData {

    /**
     * Holds the username.
     */
    username: string = "";
    
    /**
     * Holds the password.
     */
    password: string = "";
    
    /**
     * Holds the message.
     */
    message: string = "";
}