import { Component, OnInit } from '@angular/core';
import { UserEdit } from '../user/user';
import { AuthenticationService } from '../authentication/authentication.service';
import { Language } from '../generic/language';
import { FeatureType } from '../feature/feature-type';
import { AuthenticationException } from '../generic/errors';
import { ProfileService } from './profile.service';
import { LOADING_GIF } from '../generic/img-pathes';
import { arrayContains } from '../generic/array-utils';

/**
 * The component for the profile.
 */
@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css',
})
export class ProfileComponent implements OnInit {

  /**
   * Holds the gif to display when we are loading a feature.
   */
  loadingFeatureImg: string = LOADING_GIF;

  /**
   * Holds the default token. This will be displayed, before the user decides to show it.
   */
  private defaultToken: string = "**************";

  /**
   * Holds the user for the profile.
   */
  user: UserEdit;

  /**
   * Holds the token.
   */
  token: string = this.defaultToken;

  /**
   * Holds if the token is displayied.
   */
  isTokenDisplayed: boolean = false;

  /**
   * Holds a map containing the info for the feature types if they are logged-in.
   */
  featureLoggedIn: Map<FeatureType, boolean> = new Map<FeatureType, boolean>();

  /**
   * A message that might contain a failure message.
   */
  failureMessage: string = '';

  /**
   * Holds if the features are initializing.
   */
  initializingFeatures: boolean = true;

  /**
   * Holds if the features are currently loading.
   */
  loadingFeatures: Array<FeatureType> = new Array<FeatureType>();

  /**
   * The constructor.
   *
   * @param authenticationService The authentication service.
   * @param profileService        The profile service.
   */
  constructor(private authenticationService: AuthenticationService, private profileService: ProfileService) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    if (this.user == null) {
      throw new AuthenticationException("No user is logged in while someone was able to go to the profile page");
    }
    this.initFeatureLoggedIn();
  }

  /**
   * Initializes the {@link featureLoggedIn}.
   */
  private initFeatureLoggedIn(): void {
    this.initializingFeatures = true;
    this.profileService.findLoggedInFeatures()
      .then(loggedIn => {
        let notLoggedIn = this.user.featureTypes;
        for (let feature of loggedIn) {
          this.featureLoggedIn.set(feature, true);
          notLoggedIn = notLoggedIn.filter(f => f != feature);
        }
        for (let feature of notLoggedIn) {
          this.featureLoggedIn.set(feature, false);
        }
        this.loadingFeatures = new Array<FeatureType>();
        this.initializingFeatures = false;
      });
  }

  /**
   * Returns if the provided feature is loading, false otherwise, false otherwise.
   * 
   * @param feature The feature to check.
   * @returns true if the provided feature is loading, false otherwise.
   */
  isFeatureLoading(feature: FeatureType): boolean {
    return arrayContains(this.loadingFeatures, feature);
  }

  /**
   * Returns if the user is logged-in for the feature with the provided type.
   *
   * @param featureType The feature type.
   * @returns true if the user is logged-in for the feature with the provided type, false otherwise.
   */
  isLoggedIn(featureType: FeatureType): boolean {
    let result = this.featureLoggedIn.get(featureType);
    return result == undefined ? false : result;
  }

  /**
   * Logs the use in/out of the feature with the provided type.
   *
   * @param featureType The feature type.
   */
  logInOut(featureType: FeatureType) {
    this.loadingFeatures.push(featureType);
    if (this.featureLoggedIn.get(featureType)) {
      this.profileService.logout(featureType)
      .then(r => {
        this.featureLoggedIn.set(featureType, false);
        this.loadingFeatures = this.loadingFeatures.filter(f => f != featureType);
      })
    } else {
      this.profileService.login(featureType);
    }
  }

  /**
   * Returns the language.
   *
   * @returns The language.
   */
  getLanguage(): string {
      return Language[this.user.language];
  }

  /**
   * Returns the features.
   *
   * @returns The features.
   */
  getFeatures(): string {
    let features = "";
    let isFirst = true;
    for(var featureIdx in this.user.featureTypes) {
      if (!isFirst) {
        features = features + ", ";
      }
      var feature = this.user.featureTypes[featureIdx];
      features = features + FeatureType[feature];
      isFirst = false;
    }
    return features;
  }

  /**
   * Changes the {@link token} to the real token of the {@link user}.
   */
  displayToken(): void {
    this.token = this.user.token;
    this.isTokenDisplayed = true;
  }

  /**
   * Changes the {@link token} to the {@link defaultToken} token.
   */
  hideToken(): void {
    this.token = this.defaultToken;
    this.isTokenDisplayed = false;
  }

  /**
   * Returns the name of the channel if the user has one.
   *
   * @returns The name of the channel if the user has one.
   */
  getChannelName(): string {
    if (this.hashChannel()) {
      return this.user.channel.name;
    } else {
      return null;
    }
  }

  /**
   * Returns if the user has a channel.
   *
   * @returns true if the user has a a channel, false otherwise.
   */
  hashChannel(): boolean {
    return this.user.channel != null;
  }

}
