<form name="editCountersForm" (ngSubmit)="areCountersValid()" novalidate>
    <table *ngIf="command.counters.length > 0" class="counter-table">
        <tr>
            <th class="counter-table-header">Name</th>
            <th class="counter-table-header" style="padding: 0 10px 0 10px;">Wert</th>
            <th class="counter-table-header"></th>
        </tr>
        <tr *ngFor="let counter of command.counters">
            <td class="counter-table-cell">
                <input type="text" [value]="counter.name" (input)="counter.name = getCurrentValue($event)" required>
            </td>
            <td class="counter-table-cell" style="padding-left: 5px;">
                {{counter.value}}
            </td>
            <td class="button-cell counter-table-cell">
                <img *ngIf="counterHasChanged(counter) && !isCounterValid(counter)" [src]="acceptInactiveImg" class="button-img-centered" style="cursor: initial;" alt="save-inactive">
                <img *ngIf="counterHasChanged(counter) && isCounterValid(counter)" [src]="acceptImg" (click)="save(counter)" class="button-img-centered" alt="save">
                <img *ngIf="counterHasChanged(counter)" [src]="cancelImg" (click)="cancelCreateOrChangeCounter(counter)" class="button-img-centered" alt="cancel">
                <img *ngIf="!counterHasChanged(counter)" [src]="deleteImg" (click)="delete(counter)" class="button-img-centered" alt="delete">
            </td>
        </tr>
    </table>
    <img (click)="createCounter()" [src]="addImg" class="button-img" style="float: left;" alt="add">
</form>
