import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { AUTHENTICATION_URL } from '../generic/backend-urls';
import { LoginResponse } from './login-response';
import { LoginData } from './login-data';
import { AuthenticationService } from '../authentication/authentication.service';
import { Router } from '@angular/router';

/**
 * A service for the login.
 */
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  /**
   * The constructor.
   *
   * @param http                  The http client.
   * @param authenticationService The authentication service.
   * @param router                The router.
   */
  constructor(private http: HttpClient, private authenticationService: AuthenticationService, private router: Router) { }

  /**
   * Logs in the user with the login data.
   *
   * @param loginData The login data.
   */
  login(loginData: LoginData): void {
    this.authenticationService.logout();
    let username = loginData.username;
    let password = loginData.password;
    (this.http.post(AUTHENTICATION_URL + "/login", { username, password }) as Observable<LoginResponse>)
      .subscribe(new LoginHandler(loginData, this.authenticationService, this.router));
  }

}

/**
 * The observer handling the response from the login.
 */
class LoginHandler implements Observer<LoginResponse> {

  /**
   * The constructor.
   *
   * @param loginData             The login data.
   * @param authenticationService The authentication service.
   * @param router                The router.
   */
  constructor(private loginData: LoginData, private authenticationService: AuthenticationService, private router: Router) {

  }

  next(loginResponse: LoginResponse) {
      this.authenticationService.setLoggedIn(loginResponse);
      this.router.navigate(['profile']);
  }

  error(err: { status: string | number; }) {
      let status = err.status;
      if (status == 403) {
          this.loginData.message = "Falsche login daten.";
      } else if(status == 418) {
          this.loginData.message = "Du hast dich bereits angemeldet.";
      } else {
          console.error("Error occurred on login", err);
          this.loginData.message = "Ein fehler ist beim Login aufgetreten. Bitte versuch es erneut. \n Wenn es wieder schief geht, dann melde dich bitte bei support@pinkpanth3r.de."
      }
  }

  complete() {}

}
