<h1>Was noch kommt?</h1>
Auf dieser Seite siehst du, was für die Zukunft geplant ist. Es wird in der Reihenfolge vollbracht, in welcher es hier aufgelistet ist und es kann jederzeit etwas dazu kommen.<br>
Solltest du etwas von den weiter unten stehenden Sachen bevorzugen (<span style="color: deeppink;">3)</span>) oder dir irgendwas einfällt, was du hier gerne dabei hättest, dann schreib gerne eine E-Mail an features&#64;pinkpanth3r.de.
Ich kann dir nicht versprechen, dass ich es dadurch direkt ändere, sondern nur, wenn es von mehreren gewählt wird oder es mir Sinnvoll erscheint.

<div class="whats-next-header"><span class="enumeration">1)</span> +, -, *, / zum Counter hinzufügen</div>
Du kannst aussuchen, ob du den Counter x mit dem Wert y addieren, subtrahieren, multiplizieren oder dividieren willst.

<div class="whats-next-header"><span class="enumeration">2)</span> Rang der Benutzer hinzufügen</div>
Es wird die Möglichkeit gegeben, zu einem Befehl den Rang der Benutzer hinzufügen, welche den Befehl benutzen dürfen.

<div class="whats-next-header"><span class="enumeration">3)</span> Wählen von beliebteren Features</div>
Ich füge hier Daumen hoch/runter hinzu, damit ihr leichter darüber abstimmen könnt, was ihr bevorzugt.

<div class="whats-next-header"><span class="enumeration">4)</span> Counter in mehreren Befehlen benutzen</div>
Du kannst deine Counter in mehreren Befehlen benutzen. Das heist du kannst dir z.B. einen Befehl hinzufügen, bei welchem es immer erhöht wird und einen anderen, bei welchem es verringert wird.

<div class="whats-next-header"><span class="enumeration">5)</span> Befehle mit bestimmten Benutzern teilen</div>
Du kannst Benutzer auswählen, welche deinen Befehl verwenden können.

<div class="whats-next-header"><span class="enumeration">6)</span> Suchfeld hinzufügen</div>
Ich werde der Seite für das Suchen nach Befehlen von anderen um ein Feld für das Suchen erweitern.

<div class="whats-next-header"><span class="enumeration">7)</span> Suchfeld hinzufügen</div>
Ich werde die Seite, für das Suchen nach Befehlen von anderen, um ein Feld für das Suchen erweitern.
