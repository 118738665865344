import { Component, OnInit } from '@angular/core';
import { DESC_IMG_PATH } from '../desc-basics';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

/**
 * The component for the description of the links feature.
 */
@Component({
  selector: 'links-desc',
  standalone: true,
  imports: [NgIf, RouterLink],
  templateUrl: './links-desc.component.html',
})
export class LinksDescComponent implements OnInit{
  
  /**
   * Holds the path to the create command image for the links.
   */
  createCommand: string = DESC_IMG_PATH + "/links-create-command.jpg";

  /**
   * Holds the path to the create nightbot command image for the bot.
   */
  createNightbotCommand: string = DESC_IMG_PATH + "/links-nightbot-create.jpg";

  /**
   * Holds the path to the executed command image for the bot.
   */
  executedCommand: string = DESC_IMG_PATH + "/links-moin.jpg";

  /**
   * Holds if the user is logged in.
   */
  isLoggedIn: boolean = false;

  /**
   * The constructor.
   * 
   * @param authenticationService The authentication service.
   */
  constructor(private authenticationService: AuthenticationService) {

  }

  ngOnInit(): void {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
  }
}
