import { Component } from '@angular/core';
import { CreateChannelService } from './create-channel.service';
import { BOT_NAME } from '../../app.basics';

/**
 * The component for the creating a channel.
 */
@Component({
  selector: 'create-channel',
  templateUrl: './create-channel.component.html',
})
export class CreateChannelComponent {

  /**
   * Holds the name of the channel.
   */
  channelName: string;

  /**
   * Holds the name of the bot.
   */
  botName: string = BOT_NAME;

  /**
   * The constructor.
   *
   * @param createChannelService The create channel service.
   */
  constructor(private createChannelService: CreateChannelService) {

  }

  /**
   * Creates the channel if everything is filled correctly.
   */
  save() {
    if (this.channelName == null || this.channelName.trim() == '') {
      return;
    }
    this.createChannelService.save(this.channelName);
  }
}
