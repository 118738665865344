
/**
 * The feature types existing in the twitch utils.
 */
export enum FeatureType {
    
    /**
     * The feature type for spotify.
     */
    SPOTIFY = "Spotify",
    
    /**
     * The feature type for twitch.
     */
    TWITCH = "Twitch",
}