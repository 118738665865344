<h1>Willkommen bei meinen Twitch Utils</h1>
Mir ist bewusst, dass diese Homepage nicht wirklich schick ist - insbesondere auf dem Handy - und es ist auch noch nicht alles vorhanden (<a class="default-link" [routerLink]="'whats-next'" target="_blank">Was kommt noch?</a>).<br>
Das liegt daran, dass dieses Programm sich noch in der Alpha Phase befindet.
Hier geht es zunächst darum, dass alles funktioniert.<br>
An die von euch die sich wunder, wieso dies schon in der Alpha Phase öffentlich ist: Ich mache das hier privat und dadurch gibt es auch keine weiteren Menschen, die es testen.<br>
Es sollte eigentlich nie zu Problemen kommen, aber ich bin leider nicht perfekt. Es kann vorkommen, dass ich etwas übersehe. Dafür gibt es die Alpha Phase.<br>
<b>Die Sicherheit ist schon gewährt!</b><br>
<br>
<u>Hierbei geht es derzeit um das Erstellen von <b>Befehlen</b> für deinen Kanal.</u><br>
Dabei gibt es 2 Möglichkeiten:
<ul>
	<li>
		Über den Twitch Bot*
	</li>
	<li>
		Über Links, welche du z.B. bei <a class="default-link" href="https://www.nightbot.tv" target="_blank">Nightbot</a> verwenden kannst.
	</li>
</ul>
Außerdem gibt es auch noch ein paar andere Sachen, welche du im Menü unter Features findest - im Moment leider nur Spotify<span emoji="sweat-smile"></span>. Möglicherweise kommt in der Zukunft noch mehr dazu.<br>
Dieses Projekt ist noch ziemlich klein und es wird mit der Zeit wachsen.<br>
Da es derzeit noch in der Alpha Phase ist, gibt es allerdings nur eine begrenzte Anzahl an Plätzen.<br>
<br>
<b>ALSO BEEIL DICH LIEBER!</b><br>
<br>
Falls du Interesse an meinem Projekt hast oder fragen dazu, dann schreibe mir gerne eine E-Mail an pinkpanther&#64;pinkpanth3r.de.<br>
Wenn dir irgendwas einfällt, was du hier gerne dabei hättest, dann schreibe gerne eine E-Mail an features&#64;pinkpanth3r.de.<br>
<br>
<div class="bottom-desc">
	* Ich wäre euch dankbar, wenn zumindest ein paar von euch den Bot benutzen. Es ist wie gesagt noch in der Alpha Phase und da brauche ich nun mal jemanden zum Testen.<br>
</div>
