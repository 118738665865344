import { Injectable } from '@angular/core';
import { CommandEdit, NotOwnedCommandDisplay } from '../command';
import { CommandsService } from '../commands.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { HttpClient } from '@angular/common/http';
import { COMMAND_URL } from '../../generic/backend-urls';
import { Observer } from 'rxjs';
import { InternalErrorException } from '../../generic/errors';
import { CommandEditService } from '../edit/command-edit.service';

/**
 * A service for the command searches.
 */
@Injectable({
  providedIn: 'root'
})
export class CommandSearchService {

  /**
   * The constructor.
   *
   * @param commandsService       The commands service.
   * @param commandEditService    The command edit service.
   * @param http                  The http client.
   * @param authenticationService The authentication service.
   */
  constructor(private commandsService: CommandsService,
              private commandEditService: CommandEditService,
              private http: HttpClient,
              private authenticationService: AuthenticationService) { }

  /**
   * Adds the provided command to the user.
   *
   * @param command The command.
   */
  addCommandToUser(command: NotOwnedCommandDisplay): void {
    this.commandsService.addToUser(command);
  }

  /**
   * Adds the provided command to the channel.
   *
   * @param command The command.
   */
  addCommandToChannel(command: CommandEdit) {
    this.commandsService.addToChannel(command);
  }

  /**
   * Returns a failure message if one occurred.
   *
   * @param toValidate The command to validate.
   * @returns A failure message if one occurred.
   */
  isCommandValid(toValidate: NotOwnedCommandDisplay | CommandEdit): string {
    return this.commandEditService.isCommandValid(toValidate);
  }

  /**
   * Searches for the commands that the user has but that have not yet been added to the channel
   * and adds them to the provided array.
   *
   * @param channelCommands The array to which the commands are to be added.
   */
  findMissingForChannel(channelCommands: CommandEdit[]): void {
    this.http.post(COMMAND_URL + '/findMissingForChannel', {})
    .subscribe(new CommandsForChannelSearchHandler(this.authenticationService, channelCommands));
  }

}

/**
 * The observer handling the response from searching for the commands not inside the current channel of the user.
 */
class CommandsForChannelSearchHandler implements Observer<CommandEdit[]> {

  /**
   * The constructor.
   *
   * @param authenticationService The authentication service.
   * @param commands              The found commands will be added to this array.
   */
  constructor(private authenticationService: AuthenticationService, private commands: CommandEdit[]) {

  }

  next(foundCommands: CommandEdit[]) {
    foundCommands.forEach((value) => this.commands.push(value));
  }

  error(err: string) {
    const errorMessage = "An error occurred for user " + this.authenticationService.getUser().id + " while trying to search for the commands for his channel";
    throw new InternalErrorException(errorMessage, err)
  }

  complete() {}

}
