import { Injectable } from '@angular/core';
import { UserEdit, UserKey } from './user';
import { AuthenticationService } from '../authentication/authentication.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { USER_URL } from '../generic/backend-urls';
import { firstValueFrom, Observer } from 'rxjs';
import { DefaultUserResponseHandler, UserEditResponse } from './user-response';
import { NotOwnedCommandDisplay } from '../command/command';
import { InternalErrorException } from '../generic/errors';

/**
 * A service for the users.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * The constructor.
   *
   * @param http                  The http client.
   * @param authenticationService The authentication service.
   */
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }

  /**
   * Saved the provided user.
   *
   * @param user            The user to save.
   * @param setWrongVersion A setter to use if the user had a wrong version.
   */
  save(user: UserEdit, setWrongVersion: (wrongVersion: boolean) => void): void {
    this.http.post(USER_URL + "/save", user)
      .subscribe(new SaveHandler(this.authenticationService, setWrongVersion));
  }

  /**
   * Adds the provided command to the user.
   *
   * @param command The command.
   */
  addCommand(command: NotOwnedCommandDisplay): void {
    this.http.post(USER_URL + '/addCommand', command)
      .subscribe(new DefaultUserResponseHandler(this.authenticationService, "An error occurred while trying to add the command " +
                                                                            command.id +
                                                                            " to the user " +
                                                                            this.authenticationService.getUser().id));
  }

  /**
   * Returns a promise if the provided username already exists.
   *
   * @param username The username.
   * @returns A promise if the provided username already exists.
   */
  async checkNameExists(username: string): Promise<boolean> {
    return firstValueFrom<boolean>(this.http.post<boolean>(USER_URL + '/usernameExist',
                                   {user: new UserKey(this.authenticationService.getUser().id), username: username}));
  }

}

/**
 * The observer handling the response from saving the user.
 */
class SaveHandler implements Observer<UserEditResponse> {

  /**
   * The constructor.
   *
   * @param authenticationService The authentication service.
   * @param setWrongVersion       A setter to use if the user had a wrong version.
   */
  constructor(private authenticationService: AuthenticationService, private setWrongVersion: (wrongVersion: boolean) => void) {

  }

  next(userEditResponse: UserEditResponse) {
    this.authenticationService.refreshUser(userEditResponse);
    this.setWrongVersion.call(this, false);
  }

  error(err: HttpErrorResponse) {
    let status = Number(err.status);
    if (status == 406) {
      this.authenticationService.reloadUser();
      this.setWrongVersion.call(this, true);
      return;
    }
    console.error("An error occurred while trying to save a user", err);
    throw new InternalErrorException("An error occurred while trying to save a user", err.message)
  }

  complete() {}

}
