<h1>{{channel.name}}</h1>

<table>
    <tr>
        <td colspan="3" class="sub-th">
            <div class="row">
                <div class="column">Befehle</div>
                <div class="column">
                    <img (click)="addCommand()" class="button-img" style="float: right;" alt="search command" [src]="magnifierImg">
                </div>
            </div>
        </td>
    </tr>
    <tr *ngIf="channel.commands.length > 0">
        <th id="first-column">Name</th>
        <th id="second-column">Text</th>
        <th class="button-cell"></th>
    </tr>
    <tr *ngFor="let command of channel.commands">
        <td class="name-output">
            <div>{{command.name}}</div>
        </td>
        <td class="text-output" >
            <div>{{command.text}}</div>
        </td>
        <td class="button-cell" >
            <img (click)="removeCommand(command)" class="button-img-centered" alt="remove" [src]="removeImg">
        </td>
    </tr>
</table>
