/**
 * Holds the basic URL for the backend.
 */
// for development
// const BASE_URL: string = "http://localhost:8080";

// for live server
const BASE_URL: string = "https://pinkpanth3r.de";

/**
 * Holds the URL for the authentication.
 */
export const AUTHENTICATION_URL: string = BASE_URL + "/auth";

/**
 * Holds the URL for the user.
 */
export const USER_URL: string = BASE_URL + "/user";

/**
 * Holds the URL for the channel.
 */
export const CHANNEL_URL: string = BASE_URL + "/channel";

/**
 * Holds the URL for the command.
 */
export const COMMAND_URL: string = BASE_URL + "/command";

/**
 * Holds the URL for the features.
 */
export const FEATURE_URL: string = BASE_URL + "/feature";

/**
 * Holds the URL for the spotify api.
 */
export const SPOTIFY_URL: string = BASE_URL + "/spotify";

/**
 * Holds the URL for the twitch api.
 */
export const TWITCH_URL: string = BASE_URL + "/twitch";
