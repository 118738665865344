import { Injectable } from '@angular/core';
import { AbstractCommand, CommandEdit, CommandKey, IdentifiedCommand, NotOwnedCommandDisplay } from './command';
import { AuthenticationService } from '../authentication/authentication.service';
import { HttpClient } from '@angular/common/http';
import { COMMAND_URL } from '../generic/backend-urls';
import { firstValueFrom, Observer } from 'rxjs';
import { DefaultUserResponseHandler } from '../user/user-response';
import { FeatureType } from '../feature/feature-type';
import { InternalErrorException } from '../generic/errors';
import { UserService } from '../user/user.service';
import { ChannelService } from '../channel/channel.service';
import { CommandEditService as CommandEditService } from './edit/command-edit.service';
import { Router } from '@angular/router';

/**
 * A service for the commands.
 */
@Injectable({
  providedIn: 'root'
})
export class CommandsService {

  /**
   * The constructor.
   *
   * @param http                  The http client.
   * @param authenticationService The authentication service.
   * @param userService           The user service.
   * @param channelService        The channel service.
   * @param router                The router.
   */
  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private channelService: ChannelService,
              private commandEditService: CommandEditService,
              private router: Router) { }

  /**
   * Returns the commands owned by the user.
   *
   * @returns The commands owned by the user.
   */
  findOwnedCommands(): CommandEdit[] {
    const user = this.authenticationService.getUser();
    return user.ownedCommands;
  }

  /**
   * Returns the commands not owned by the user but used by him/her.
   *
   * @returns The commands not owned by the user but used by him/her.
   */
  findNotOwnedCommandsFromUser(): NotOwnedCommandDisplay[] {
    const user = this.authenticationService.getUser();
    return user.notOwnedCommands;
  }

  /**
   * Searches the not owned commands for the user.
   */
  async findNotOwnedCommandsForUser(): Promise<NotOwnedCommandDisplay[]> {
    return await firstValueFrom<NotOwnedCommandDisplay[]>(this.http.post<NotOwnedCommandDisplay[]>(COMMAND_URL + '/findNotOwned', {}));
  }

  /**
   * Saves the provided command.
   *
   * @param command The command.
   */
  save(command: CommandEdit): void {
    const isCreated = command.id == -1;
    const errorMessage = this.createErrorMessage(command, (isCreated ? "create" : "update"));
    this.http.post(COMMAND_URL + "/save", command)
      .subscribe(new DefaultUserResponseHandler(this.authenticationService, errorMessage, this.router, "commands"));
  }

  /**
     * Creates a command.
     */
  createCommand(): void {
    this.router.navigate(["commands/create"], {queryParams: {routeURL: 'commands', forCreate: true}});
  }

  /**
   * Edits the provided command.
   *
   * @param command The command.
   */
  editCommand(command: CommandEdit): void {
    this.router.navigate(["commands/edit"], {queryParams: {routeURL: 'commands', command: command.id}});
  }

  /**
   * Returns a promise for the reloaded version of the provided command.
   *
   * @param command The command to reload.
   * @returns The promise for the reloaded command.
   */
  reloadCommand(command: IdentifiedCommand): Promise<CommandEdit> {
      return this.commandEditService.reloadCommand(command);
  }

  /**
   * Deletes the provided command.
   *
   * @param command The command.
   */
  delete(command: CommandEdit, setWrongVersion: (wrongVersion: boolean) => void) {
    const errorMessage = this.createErrorMessage(command, "delete");
    this.http.post(COMMAND_URL + "/delete", command)
    .subscribe(new DefaultUserResponseHandler(this.authenticationService, errorMessage, null, null, setWrongVersion));
  }

  /**
   * Removes the provided command from the user.
   *
   * @param command The command.
   */
  removeNotOwned(command: NotOwnedCommandDisplay) {
    const commandKey = new CommandKey(command.id);
    this.http.post(COMMAND_URL + "/remove", commandKey)
      .subscribe(this.createDefaultUserResponseHandler(command, "remove"));
  }

  /**
   * Returns the missing feature type for the provided command, or null if non exists.
   *
   * @param notOwnedCommand The not owned command to check.
   * @returns The missing feature type for the provided command, or null if non exists.
   */
  findMissingFeature(notOwnedCommand: NotOwnedCommandDisplay): FeatureType {
    const userFeatures = this.authenticationService.getUser().featureTypes;
    const commandFeature = notOwnedCommand.featureType;

    if (!userFeatures.includes(commandFeature)) {
      return commandFeature;
    }
    return null;
  }

  /**
   * Returns if the user is is allowed to use this command.
   *
   * @param notOwnedCommand The command.
   * @returns true if the user is allowed to use this command, false otherwise.
   */
  isCommandAllowed(notOwnedCommand: NotOwnedCommandDisplay): boolean {
    return this.findMissingFeature(notOwnedCommand) == null;
  }

  /**
   * Returns if the user already uses this command.
   *
   * @param command The not owned command to check.
   * @returns true if the user already uses this command, false otherwise.
   */
  doesUserUseCommand(command: IdentifiedCommand): boolean {
    let userNotOwnedCommands = this.authenticationService.getUser().notOwnedCommands;
    for (let userNotOwnedCommand of userNotOwnedCommands) {
        if (userNotOwnedCommand.id == command.id) {
          return true;
        }
    }
    return false;
  }

  /**
   * Adds the provided command to the user.
   *
   * @param command The command.
   */
  addToUser(command: NotOwnedCommandDisplay): void {
    this.userService.addCommand(command);
  }

  /**
   * Adds the provided command to the channel.
   *
   * @param command The command.
   */
  addToChannel(command: CommandEdit) {
    this.channelService.addCommand(command);
  }

  /**
   * Creates a default user response handler for the provided command with the reason inside the error message.
   *
   * @param command The command.
   * @param reason  The reason.
   * @returns A default user response handler.
   */
  private createDefaultUserResponseHandler(command: AbstractCommand, reason: string): DefaultUserResponseHandler {
    const errorMessage = this.createErrorMessage(command, reason);
    return new DefaultUserResponseHandler(this.authenticationService, errorMessage);
  }

  /**
   * Creates an error message for the provided command with the reason inside it.
   *
   * @param command The command.
   * @param reason  The reason.
   * @returns An error message.
   */
  private createErrorMessage(command: AbstractCommand, reason: string): string {
    const isCreated = command.id == -1;
    const commandMessage = reason  + " the command '" + command.name  + "'" + (!isCreated ? " (ID: " + command.id + ") " : " ") + "with the text " + command.text;
    const errorMessage = "An error occurred for user " + this.authenticationService.getUser().id + " while trying to " + commandMessage;
    return errorMessage;
  }

}

/**
 * The observer handling the response from searching not owned commands.
 */
class NotOwnedCommandsSearchHandler implements Observer<NotOwnedCommandDisplay[]> {

  /**
   * The constructor.
   *
   * @param commands The found commands will be added to this array.
   */
  constructor(private authenticationService: AuthenticationService, private commands: IdentifiedCommand[]) {

  }

  next(foundCommands: IdentifiedCommand[]) {
    foundCommands.forEach((value) => this.commands.push(value));
  }

  error(err: string) {
    const errorMessage = "An error occurred for user " + this.authenticationService.getUser().id + " while trying to search for missing commands";
    throw new InternalErrorException(errorMessage, err)
  }

  complete() {}

}
