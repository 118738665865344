import {Component} from '@angular/core';
import { AuthenticationService } from './authentication/authentication.service';
import { Router } from '@angular/router';

/**
 * The root component.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {

  /**
   * The constructor.
   * 
   * @param authenticationService The authentication service.
   * @param router The router.
   */
  constructor(private authenticationService: AuthenticationService, private router: Router) {

  }
  
  /**
   * Returns the id for the menu item.
   * 
   * @param menuItem The menu item.
   * @returns The id for the menu item.
   */
  getIdFor(menuItem: string): string {
    return this.router.url == menuItem ? "menu-selected" : "";
  }

  /**
   * Gets called to logout the user.
   */
  logout(): void {
    this.authenticationService.logout();
  }

  /**
   * Returns if the user is logged in.
   *
   * @returns true if the user is logged in, false otherwise.
   */
  isLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }

  /**
   * Returns if a user is logged-in and has a channel.
   * 
   * @returns true if a user is logged-in and has a channel, false otherwise.
   */
  userHasChannel(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    return this.authenticationService.getUser().channel != null;
  }
}
