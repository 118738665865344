import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ACCEPT_IMG, ACCEPT_INACTIVE_IMG, ADD_IMG, CANCEL_IMG, DELETE_IMG } from '../../generic/img-pathes';
import { CounterEdit, IdentifiedCounter } from '../counter';
import { CommandEdit, CommandKey } from '../../command/command';
import { containsIdentified, filterIdentified } from '../../generic/array-utils';
import { NEW_COUNTER_BASE_ID } from './counter-basics';
import { InternalErrorException } from '../../generic/errors';
import { NgFor, NgIf } from '@angular/common';
import { AbstractStandaloneComponent } from '../../generic/standalone-component';

/**
 * The component for the creating/editing counters of a command.
 */
@Component({
  selector: 'counter-edit',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
  ],
  templateUrl: './counter-edit.component.html',
  styleUrl: './counter-edit.component.css',
})
export class CounterEditComponent extends AbstractStandaloneComponent implements OnInit, AfterViewInit {

  /**
   * Holds the path to the accept image.
   */
  acceptImg: string = ACCEPT_IMG;

  /**
   * Holds the path to the inactive accept image.
   */
  acceptInactiveImg: string = ACCEPT_INACTIVE_IMG;
  
  /**
   * Holds the path to the add image.
   */
  addImg: string = ADD_IMG;

  /**
   * Holds the path to the cancel image.
   */
  cancelImg: string = CANCEL_IMG;

  /**
   * Holds the path to the delete image.
   */
  deleteImg: string = DELETE_IMG;
  
  /**
   * Holds the method to get called whenever the save button shall be disabled.
   */
  @Output("saveButtonDisabled")
  disableSaveButton: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Holds the method to get called whenever a counter has been changed.
   */
  @Output("counterChanged")
  setCounterChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  /**
   * Holds the command of the counters.
   */
  @Input({alias:"command", required: true})
  command: CommandEdit;

  /**
   * Holds the counter id for new created counters
   */
  newCounterId: number = NEW_COUNTER_BASE_ID;

  /**
   * Holds the counters for their id.
   */
  countersById: Map<number, CounterEdit> = new Map<number, CounterEdit>();

  /**
   * Holds the counters which where saved.
   */
  savedCounters: Map<number, CounterEdit> = new Map<number, CounterEdit>();

  /**
   * Holds the counters which where changed.
   */
  changedCounters: Array<CounterEdit> = new Array<CounterEdit>();

  ngOnInit(): void {
    for(let counter of this.command.counters) {
      this.countersById.set(counter.id, CounterEdit.copy(counter));
    }
  }

  ngAfterViewInit(): void {
    if (!this.disableSaveButton.observed) {
      throw new InternalErrorException("A disableSaveButton should always been provided");
    }
    if (!this.setCounterChanged.observed) {
      throw new InternalErrorException("A setCounterChanged should always been provided");
    }
  }

  /**
   * Saves the counter.
   */
  save(counter: CounterEdit) {
    this.savedCounters.set(counter.id, CounterEdit.copy(counter));
    if (!containsIdentified(this.command.counters, counter)) {
      this.command.counters.push(counter);
    }
    let allSaved = true;
    for (let cnt of this.changedCounters) {
      if (!this.savedCounters.has(cnt.id)) {
        allSaved = false;
        break;
      }
    }

    this.disableSaveButton.emit(!allSaved);
  }

  /**
   * Creates a new counter.
   */
  createCounter() {
    const counter: CounterEdit = new CounterEdit(this.newCounterId, 0, "", 0, new CommandKey(this.command.id));
    this.newCounterId = this.newCounterId + 1;
    this.command.counters.push(counter);
    this.changedCounters.push(counter);
    this.disableSaveButton.emit(true);
  }
  
  /**
   * Checks if the provided counter has been edited.
   *
   * @param counter The counter.
   * @returns true if the counter has been changed, false otherwise.
   */
  counterHasChanged(counter: CounterEdit): boolean {
    let previousCounter: CounterEdit = this.savedCounters.get(counter.id);
    if (previousCounter == null && !this.isCreatedCounter(counter)) {
      previousCounter = this.countersById.get(counter.id);
    } 
    
    let result: boolean = false;
    if (previousCounter == null) {
      result = true;
    } else if (counter.name.trim() != previousCounter.name.trim()) {
      result = true;
    } else if (counter.value != previousCounter.value) {
      result = true;
    }
    
    const filteredChangedCounters = filterIdentified(this.changedCounters, counter);
    if (!result) {
      this.changedCounters = filteredChangedCounters;
      if (filteredChangedCounters.length == 0) {
        this.disableSaveButton.emit(false);
      }
    } else {
      this.disableSaveButton.emit(true);
      if (filteredChangedCounters.length == this.changedCounters.length) {
        this.changedCounters.push(counter);
      }
    }
    if (this.changedCounters.length == 0) {
      this.setCounterChanged.emit(false)
    } else {
      this.setCounterChanged.emit(true)
    }
    return result;
  }

  /**
   * Returns if the provided counter was created.
   * 
   * @param counter The counter.
   * @returns true if the provided counter was created, false otherwise.
   */
  private isCreatedCounter(counter: IdentifiedCounter): boolean {
    return counter.id >= NEW_COUNTER_BASE_ID && counter.id < this.newCounterId
  }

  /**
   * Returns if the current counters are valid.
   * 
   * @returns true if the current counters are valid, false otherwise.
   */
  areCountersValid(): boolean {
    for(let counter of this.command.counters) {
      if (!this.isCounterValid(counter)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Returns if the provided counter is valid.
   * 
   * @param counter The counter.
   * @returns true if the provided counter is valid, false otherwise.
   */
  isCounterValid(counter: CounterEdit): boolean {
    if (counter.name.trim() == "" || counter.value < 0) {
      return false;
    }

    return true;
  }
  
  /**
   * Deletes the provided command.
   *
   * @param command The command.
   */
  delete(counter: CounterEdit) {
    this.command.counters = filterIdentified(this.command.counters, counter);
    this.savedCounters.delete(counter.id);
    if (!this.isCreatedCounter(counter)) {
     this.disableSaveButton.emit(false);
     this.setCounterChanged.emit(true);
     this.changedCounters.push(counter);
    }
  }
  
  /**
   * Cancels the current creation/changing of the provided counter.
   *
   * @param counter The counter.
   */
  cancelCreateOrChangeCounter(counter: CounterEdit): void {
    this.changedCounters = filterIdentified(this.changedCounters, counter);
    if (this.isCreatedCounter(counter)) {
        this.command.counters = filterIdentified(this.command.counters, counter);
        if (this.changedCounters.length == 0) {
          this.setCounterChanged.emit(false);
          this.disableSaveButton.emit(false);
        }
        return;
    }
    let previousCommand: CounterEdit = this.savedCounters.get(counter.id);
    if(previousCommand == null) {
      previousCommand = this.countersById.get(counter.id);
    }
    if (previousCommand == null) {
      throw new InternalErrorException("");
    }
    counter.name = previousCommand.name;
    counter.value = previousCommand.value;
  }
 }
