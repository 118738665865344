import { Component, Input, OnInit} from '@angular/core';
import { CommandEdit, IdentifiedCommand, NotOwnedCommandDisplay } from '../command';
import { CommandSearchService } from './command-search.service';
import { Router } from '@angular/router';
import { InternalErrorException } from '../../generic/errors';
import { ACCEPT_IMG as ACCEPT_IMG } from '../../generic/img-pathes';

/**
 * The component for the command search.
 */
@Component({
  selector: 'search',
  templateUrl: './command-search.component.html',
  styleUrl: '../commands.css',
})
export class CommandSearchComponent implements OnInit{

  /**
   * Holds the path to the accept image.
   */
  acceptImg: string = ACCEPT_IMG;

  /**
   * The URL for the router to go to when the user clicks on the back button.
   */
  @Input('routeURL') routeURL: string;

  /**
   * Holds if the search was created for a channel.
   */
  @Input('forChannel') forChannel: boolean;

  /**
   * Holds if an error occurred for the command.
   */
  hasError: boolean = false;

  /**
   * Holds the message to display for the error.
   */
  errorMessage: string = "";

  /**
   * Holds the commands which the user has but are not added to the channel yet.
   * They are only filled if forChannel == true.
   */
  missingChannelCommands: CommandEdit[] = new Array();

  /**
   * The constructor.
   *
   * @param commandSearchService The command search service.
   * @param router               The router.
   */
  constructor(private commandSearchService: CommandSearchService, private router: Router) {

  }

  ngOnInit(): void {
    if (this.forChannel) {
      this.commandSearchService.findMissingForChannel(this.missingChannelCommands);
    }
  }

  /**
   * Adds the provided command to the user.
   *
   * @param command The command.
   */
  addCommand(command: IdentifiedCommand) {
    if (this.forChannel) {
      this.handleAddCommandForChannel(command);
    } else {
      this.handleAddCommandForUser(command);
    }
  }

  /**
   * Adds the provided command to the channel.
   *
   * @param command The command.
   */
  handleAddCommandForChannel(command: IdentifiedCommand): void {
    const commandEdit: CommandEdit = command as CommandEdit;
    const commandIdx = this.missingChannelCommands.indexOf(commandEdit);
    if (commandIdx === -1) {
      throw new InternalErrorException("The provided command " + commandEdit + " is not contained in the missingChannelCommands for the channel");
    }
    this.commandSearchService.addCommandToChannel(commandEdit);
    this.missingChannelCommands = this.missingChannelCommands.filter(cmd => cmd.id != command.id);
  }

  /**
   * Adds the provided command to the user.
   *
   * @param command The command.
   */
  handleAddCommandForUser(command: IdentifiedCommand): void {
    const notOwnedCommand: NotOwnedCommandDisplay = command as NotOwnedCommandDisplay;
    if (!this.isCommandValidForUser(notOwnedCommand)) {
      return;
    }

    this.commandSearchService.addCommandToUser(notOwnedCommand);
  }

  /**
   * Returns if the provided command is valid.
   *
   * @param toValidate The command to validate.
   * @returns true if the provided command is valid, false otherwise.
   */
  private isCommandValidForUser(toValidate: NotOwnedCommandDisplay): boolean {
    const errorMessage = this.commandSearchService.isCommandValid(toValidate);
    if (errorMessage != null) {
      this.hasError = true;
      this.errorMessage = errorMessage;
    } else {
      this.hasError = false;
      this.errorMessage = "";
    }
    return errorMessage == null;
  }

  /**
   * Get's called when the user clicks on the back button and returns to the {@link routeURL}.
   */
  back() {
    if (this.routeURL == null) {
      throw new Error('The back function was called while the redirectURL is null');
    }
    this.router.navigate([this.routeURL]);
  }
}
