import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { UserEdit } from '../user/user';
import { AuthenticationService } from '../authentication/authentication.service';

/**
 * A service for the settings.
 */
@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  /**
   * The constructor.
   *
   * @param userService           The user service.
   * @param authenticationService The authentication service.
   */
  constructor(private userService: UserService, private authenticationService: AuthenticationService) { }

  /**
   * Saved the provided user.
   *
   * @param user            The user to save.
   * @param setWrongVersion A setter to use if the user had a wrong version.
   */
  save(user: UserEdit, setWrongVersion: (wrongVersion: boolean) => void): void {
      this.userService.save(user, setWrongVersion);
  }

  /**
   * Returns a promise with a token generated for a user.
   *
   * @return The promise with a token generated for a user.
   */
  generateToken(): Promise<string> {
    return this.authenticationService.generateToken()
  }

  /**
   * Returns a promise if the provided username already exists.
   *
   * @param username The username.
   * @returns A promise if the provided username already exists.
   */
  checkNameExists(username: string): Promise<boolean> {
    return this.userService.checkNameExists(username);
  }

}
