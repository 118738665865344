import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DESC_IMG_PATH } from '../desc-basics';


/**
 * The component for the description of the command feature.
 */
@Component({
  selector: 'commands-desc',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './commands-desc.component.html',
})
export class CommandsDescComponent {

  /**
   * Holds the path to the create command image.
   */
  createCommand: string = DESC_IMG_PATH + "/create-command.jpg";
}
