import { Component, OnInit } from '@angular/core';
import { UserEdit } from '../user/user';
import { AuthenticationService } from '../authentication/authentication.service';
import { Language } from '../generic/language';
import { FeatureType } from '../feature/feature-type';
import { SettingsService } from './settings.service';
import { AuthenticationException } from '../generic/errors';
import { LOADING_GIF } from '../generic/img-pathes';

/**
 * The component for the settigs.
 */
@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit {

  /**
   * Holds the default token. This will be displayed, before the user decides to show it.
   */
  private hiddenToken: string = "**************";

  /**
   * Holds the user for the settings.
   */
  settingsUser: UserEdit;

  /**
   * Holds the token.
   */
  displayedToken: string = this.hiddenToken;

  /**
   * Holds if the token is displayied.
   */
  isTokenDisplayed: boolean = false;

  /**
   * Holds the currently selected language.
   */
  selectedLanguage: string;

  /**
   * Holds the feature types to use.
   */
  featureTypes: FeatureType[] = [FeatureType.SPOTIFY, FeatureType.TWITCH];

  /**
   * Holds the feature types by its names.
   */
  featureTypeNames = new Map<string, FeatureType>

  /**
   * Holds the languages by its names.
   */
  languageNames = new Map<string, Language>

  /**
   * Holds if an error occurred for the command.
   */
  hasError: boolean = false;

  /**
   * Holds the message to display for the error.
   */
  errorMessage: string = "";

  /**
   * Holds the gif to display instead of the save button while saving.
   */
  savingGif: string = LOADING_GIF;

  /**
   * Holds the gif to display when generating a token.
   */
  generatingTokenGif: string = LOADING_GIF;

  /**
   * Holds if the component is saving at the moment.
   */
  saving: boolean = false;

  /**
   * Holds if we are generating a token.
   */
  generatingToken: boolean = false;

  /**
   * The constructor.
   *
   * @param authenticationService The authentication service.
   * @param settingsService       The settings service.
   */
  constructor(private authenticationService: AuthenticationService, private settingsService: SettingsService) {

  }

  ngOnInit(): void {
    let user = this.authenticationService.getUser();
    if (user == null) {
      throw new AuthenticationException("No user is logged in while someone was able to go to the settings page");
    }

    this.authenticationService.subscribeToUserChange((user: UserEdit) => {
      this.settingsUser = user;
    });

    for (let [key, value] of Object.entries(Language)) {
      this.languageNames.set(value, key as Language);
    }

    for (let [key, value] of Object.entries(FeatureType)) {
      this.featureTypeNames.set(value, key as FeatureType);
    }
    this.settingsUser = UserEdit.copy(user);
    this.selectedLanguage = Language[user.language];
  }

  /**
   * Saves the user.
   */
  save() {
    this.saving = true;;
    this.areSettingsValid().then(settingsValid => {
      if(!settingsValid) {
        this.saving = false;
        this.hasError = true;
        this.errorMessage = "Es existiert bereits ein Benutzer mit diesem Namen";
        return;
      }
      this.hasError = false;
      this.errorMessage = "";
      this.settingsService.save(this.settingsUser, this.setWrongVersion.bind(this));
      this.saving = false;
    })
  }

  /**
   * Returns a promise if the settings are valid.
   *
   * @returns A promise if the settings are valid.
   */
  async areSettingsValid(): Promise<boolean> {
    return this.settingsService.checkNameExists(this.settingsUser.username)
      .then(exists => {
        if(exists) {
          return false;
        } else {
          return true;
        }
      });
  }

  /**
   * Sets if the user had a wrong version.
   *
   * @param wrongVersion true if the user had a wrong version, false otherwise.
   */
  private setWrongVersion(wrongVersion: boolean) {
    this.hasError = wrongVersion;
    if (wrongVersion) {
      this.errorMessage = "Die Version von den Einstellungen war leider nicht korrekt.<br>Bitte versuch es erneut."
      this.settingsUser = UserEdit.copy(this.authenticationService.getUser());
      this.selectedLanguage = Language[this.settingsUser.language];;
    }
  }

  /**
   * Returns the languages.
   *
   * @returns The languages.
   */
  languages(): Language[] {
    return Object.values(Language);
  }

  /**
   * Returns the features.
   *
   * @returns The features.
   */
  features(): FeatureType[] {
    return Object.values(FeatureType);
  }

  /**
   * Returns if the feature for the provided type is active.
   *
   * @param featureType The feature type.
   * @returns true if the feature for the provided type is active, false otherwise.
   */
  isFeatureActive(featureType: FeatureType): boolean {
    return this.settingsUser.featureTypes.some((currentFeatureType) => currentFeatureType == this.featureTypeNames.get(featureType));
  }

  /**
   * Gets called when a feature was add/removed.
   */
  featuresChanged(event: any, featureTypeName: string) {
    const featureType : FeatureType = this.featureTypeNames.get(featureTypeName);
    if(event.target.checked) {
      this.settingsUser.featureTypes.push(featureType);
    } else {
      this.settingsUser.featureTypes = this.settingsUser.featureTypes.filter(toFilter => toFilter != featureType);
    }
  }

  /**
   * Gets called when the selected language has changed.
   */
  languageChanged() {
    this.settingsUser.language = this.languageNames.get(this.selectedLanguage) as Language;
  }

  /**
   * Returns if the user has been changed.
   *
   * @returns true if he user has been changed, false otherwise.
   */
  userHasChanged(): boolean {
    let user = this.authenticationService.getUser();
    if (this.settingsUser.username != user.username) {
      return true;
    }
    if (this.settingsUser.email != user.email) {
      return true;
    }
    if (this.settingsUser.token != user.token) {
      return true;
    }
    if (this.settingsUser.language != user.language) {
      return true;
    }

    let settingFeatureTypes: FeatureType[] = this.settingsUser.featureTypes;
    let userFeatureTypes: FeatureType[] = user.featureTypes;
    if (settingFeatureTypes.length != userFeatureTypes.length) {
      return true;
    }
    for (var userFeatureType of userFeatureTypes) {
      let notContained = !settingFeatureTypes.some((featureType) => featureType == userFeatureType);
      if (notContained) {
        return true;
      }
    }

    return false;
  }

  /**
   * Changes the {@link displayedToken} to the real token of the {@link settingsUser}.
   */
  displayToken(): void {
    this.displayedToken = this.settingsUser.token;
    this.isTokenDisplayed = true;
  }

  /**
   * Changes the {@link displayedToken} to the {@link hiddenToken} token.
   */
  hideToken(): void {
    this.displayedToken = this.hiddenToken;
    this.isTokenDisplayed = false;
  }

  /**
   * Generates a token for a user.
   */
  generateToken() {
    this.generatingToken = true;
    this.settingsService.generateToken()
      .then(token => {
        if (this.isTokenDisplayed) {
          this.displayedToken = token;
        }
        this.settingsUser.token = token;
        this.generatingToken = false;
      });
  }

}
