import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotOwnedCommandDisplay } from '../command';
import { CommandsService } from '../commands.service';
import { FeatureType } from '../../feature/feature-type';
import { AuthenticationService } from '../../authentication/authentication.service';
import { UserEdit } from '../../user/user';
import { InternalErrorException } from '../../generic/errors';
import { ACCEPT_IMG, LOADING_GIF, MAGNIFIER_IMG, REMOVE_IMG } from '../../generic/img-pathes';
import { NgFor, NgIf, NgStyle } from '@angular/common';
import { Router } from '@angular/router';

/**
 * The component for the not owned commands.
 */
@Component({
  selector: 'not-owned-commands',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgStyle
  ],
  templateUrl: './not-owned-commands.component.html',
  styleUrl: '../commands.css',
})
export class NotOwnedCommandsComponent implements OnInit {

  /**
   * Holds the path to the magnifier image.
   */
  magnifierImg: string = MAGNIFIER_IMG;

  /**
   * Holds the path to the accept image.
   */
  acceptImg: string = ACCEPT_IMG;

  /**
   * Holds the path to the remove image.
   */
  removeImg: string = REMOVE_IMG;

  /**
   * Holds the gif to display when we are currently loading the commands.
   */
  loadingImg: string = LOADING_GIF;

  /**
   * Holds if this component was created for the not owned commands from the user.<br>
   * Default: true
   */
  @Input('fromUser')
  fromUser: boolean = true;

  /**
   * Holds an emitter for adding a not owned command.
   */
  @Output('addRequest')
  addRequest = new EventEmitter<NotOwnedCommandDisplay>();

  /**
   * Holds an emitter for removing a not owned command.
   */
  @Output('removeRequest')
  removeRequest = new EventEmitter<NotOwnedCommandDisplay>();

  /**
   * Holds if the commands are initializing.
   */
  initializingCommands: boolean = true;

  /**
   * The commands which can be selected from the user.
   */
  commands: NotOwnedCommandDisplay[] = new Array();

  /**
   * The constructor.
   *
   * @param commandsService       The commands service.
   * @param authenticationService The authentication service.
   * @param router                The router.
   */
  constructor(private commandsService: CommandsService, private authenticationService: AuthenticationService, private router: Router) {

  }

  ngOnInit(): void {
    this.initCommands(this.fromUser ? this.commandsService.findNotOwnedCommandsFromUser() : null);
    this.authenticationService.subscribeToUserChange((user: UserEdit) => {
      this.initCommands(this.fromUser ? user.notOwnedCommands : null);
    });
  }

  /**
   * Initializes the commands and everything needed for them.
   *
   * @param notOwnedCommandsFromUser The commands not owned by the user but used by him/her.
   */
  initCommands(notOwnedCommandsFromUser: NotOwnedCommandDisplay[]) {
    this.initializingCommands = true;
    this.commands = [];
    if (notOwnedCommandsFromUser != null) {
      this.commands = notOwnedCommandsFromUser;
      this.initializingCommands = false;
    } else {
      this.commandsService.findNotOwnedCommandsForUser()
      .then((notOwnedCommands) => {
        this.commands = notOwnedCommands;
        this.initializingCommands = false;
      });
    }
  }

  /**
   * Moves to the search page for the commands.
   */
  goToSearch() {
    this.router.navigate(['commands/search'], {queryParams: {routeURL: 'commands'}});
  }

  /**
   * Adds the provided command to the user.
   *
   * @param command The command.
   */
  addCommand(command: NotOwnedCommandDisplay) {
    if (this.addRequest.observed) {
      this.addRequest.emit(command);
    } else {
      throw new InternalErrorException("addCommand was called without an addRequest");
    }
  }

  /**
   * Removes the provided command from the user.
   *
   * @param command The command.
   */
  removeCommand(command: NotOwnedCommandDisplay) {
    if (this.removeRequest.observed) {
      this.removeRequest.emit(command);
    } else {
      throw new InternalErrorException("removeCommand was called without a removeRequest");
    }
  }

  /**
   * Returns the style to be used for the provided command.
   *
   * @param notOwnedCommand The command.
   * @returns The style to be used for the provided command.
   */
  createCellStyle(notOwnedCommand: NotOwnedCommandDisplay): Object {
    return {'background-color': this.disableCommand(notOwnedCommand) ? 'gray' : 'deeppink'};
  }

  /**
   * Returns if the command shall be disabled.
   *
   * @param notOwnedCommand The command.
   * @returns true if the command shall be disabled, false otherwise.
   */
  disableCommand(notOwnedCommand: NotOwnedCommandDisplay): boolean {
    if (!this.fromUser) {
      const doesUserHaveCommand: boolean = this.alreadyUsed(notOwnedCommand);
      if (doesUserHaveCommand) {
        return true;
      }
    }

    const allowed: boolean = this.commandsService.isCommandAllowed(notOwnedCommand);
    if (!allowed) {
      return true;
    }
    return false;
  }

  /**
   * Returns if the user already uses the provided command.
   *
   * @param notOwnedCommand The command.
   * @returns true if the user already uses the provided command, false otherwise.
   */
  alreadyUsed(notOwnedCommand: NotOwnedCommandDisplay): boolean {
    return this.commandsService.doesUserUseCommand(notOwnedCommand);
  }

  /**
   * Returns the name of the missing feature type for the provided command, or null if non exists.
   *
   * @param notOwnedCommand The not owned command to check.
   * @returns The name missing feature type for the provided command, or null if non exists.
   */
  findMissingFeature(notOwnedCommand: NotOwnedCommandDisplay): string {
    let missingFeature: FeatureType = this.commandsService.findMissingFeature(notOwnedCommand);
    if (missingFeature == null) {
      return null;
    }
    return FeatureType[missingFeature];
  }
}
