import {IdentifiedEntity} from "./identified-entity";


/**
 * An abstract implementation of the identified entity.<br/>
 */
export class AbstractKey implements IdentifiedEntity {

  /**
   * Holds the id.
   */
  readonly id: number;

  /**
   * The constructor.
   *
   * @param id The id.
   */
  constructor(id: number) {
    this.id = id;
  }

}
