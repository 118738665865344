import { FeatureType } from "../feature/feature-type";
import { AbstractKey } from "../generic/abstract-key";
import { IdentifiedEntity } from "../generic/identified-entity";
import { Versionable } from "../generic/versionable";
import { IdentifiedUser } from "../user/user";
import { CounterEdit } from "../counter/counter";

/**
 * The key for a command.
 */
export class CommandKey extends AbstractKey implements IdentifiedCommand {

  /**
   * The constructor.
   *
   * @param id The id.
   */
    constructor(id: number) {
        super(id);
    }
}

/**
 * The versionable key for a command.
 */
export class CommandVersionableKey extends CommandKey implements IdentifiedVersionableCommand {

  /**
   * Holds the version.
   */
  readonly version: number;

  /**
   * The constructor.
   *
   * @param id      The id.
   * @param version The version.
   */
  constructor(id: number, version: number) {
    super(id);

    this.version = version;
  }

}

/**
 * Holds the possible types for commands.
 */
export enum CommandType {

    /**
     * This commands where created by our own.
     */
    BASIC,

    /**
     * This commands where created by a user.
     */
    USER
}

/**
 * An abstract class for a command.
 */
export class AbstractCommand extends CommandKey {

  /**
   * Holds the name.
   */
  name: string;

  /**
   * Holds the text.
   */
  text: string;

  /**
   * Holds the feature type.
   */
  featureType: FeatureType;

  /**
   * The constructor.
   *
   * @param id          The id.
   * @param version     The version.
   * @param name        The name.
   * @param text        The text.
   * @param featureType The feature type.
   */
  constructor(id: number, name: string, text: string, featureType: FeatureType) {
    super(id)

    this.name = name;
    this.text = text;
    this.featureType = featureType;
  }
}

/**
 * An edit object for a command.
 */
export class CommandEdit extends AbstractCommand {

    /**
     * Holds the version.
     */
    version: number;

    /**
     * Holds the counters.
     */
    counters: CounterEdit[];

    /**
     * Holds the type.
     */
    type: CommandType;

    /**
     * Holds the owner of this command.
     */
    owner: IdentifiedUser;


    /**
     * Holds if the command is public.
     */
    public: boolean;

    /**
     * The constructor.
     *
     * @param id          The id.
     * @param version     The version.
     * @param name        The name.
     * @param text        The text.
     * @param counters    The counters.
     * @param type        The type.
     * @param owner       The owner of this command.
     * @param featureType The feature type.
     * @param isPublic    true if the command is public, false otherwise.
     */
    constructor(id: number, version: number, name: string, text: string, counters: CounterEdit[], type: CommandType, owner: IdentifiedUser, featureType: FeatureType, isPublic: boolean) {
        super(id, name, text, featureType);

        this.version = version;
        this.counters = counters;
        this.type = type;
        this.owner = owner;
        this.public = isPublic;
    }

    /**
     * Returns a copy of the provided command.
     *
     * @param command The command to copy.
     * @returns A copy of the provided commands.
     */
    public static copy(command: CommandEdit): CommandEdit {
      return new CommandEdit(command.id, command.version, command.name, command.text, command.counters, command.type, command.owner, command.featureType, command.public);
    }
}


/**
 * A command that is not owned by the user.
 */
export class NotOwnedCommandDisplay extends AbstractCommand implements IdentifiedNotOwnedCommand {

  /**
   * Holds the name of the owner of this command.
   */
  owner: string;

  /**
   * The constructor.
   *
   * @param id          The id.
   * @param name        The name.
   * @param text        The text.
   * @param owner       The owner.
   * @param featureType The feature type.
   */
  constructor(id: number, name: string, text: string, owner: string, featureType: FeatureType) {
    super(id, name, text, featureType)

    this.owner = owner;
  }

  /**
   * Returns a copy of the provided not owned command.
   *
   * @param command The not owned command to copy.
   * @returns A copy of the provided not owned command.
   */
  public static copy(command: NotOwnedCommandDisplay): NotOwnedCommandDisplay {
    return new NotOwnedCommandDisplay(command.id, command.name, command.text, command.owner, command.featureType);
  }
}


/**
 * The identified entity for command.
 */
export interface IdentifiedCommand extends IdentifiedEntity {

}

/**
 * The identified entity for a not owned command.
 */
export interface IdentifiedNotOwnedCommand extends IdentifiedCommand {

}

/**
 * The versionable identified entity for command.
 */
export interface IdentifiedVersionableCommand extends IdentifiedCommand, Versionable {

}
