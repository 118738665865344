import {IdentifiedEntity} from "../generic/identified-entity";
import {AbstractKey} from "../generic/abstract-key";
import { Versionable } from "../generic/versionable";
import { IdentifiedCommand } from "../command/command";

/**
 * The key for a counter.
 */
export class CounterKey extends AbstractKey implements IdentifiedCounter {

  /**
   * The constructor.
   *
   * @param id The id.
   */
    constructor(id: number) {
        super(id);
    }
}

/**
 * The versionable key for a counter.
 */
export class CounterVersionableKey extends CounterKey implements IdentifiedVersionableCounter {

  /**
   * Holds the version.
   */
  readonly version: number;

  /**
   * The constructor.
   *
   * @param id The id.
   * @param version The version.
   */
  constructor(id: number, version: number) {
    super(id);
    this.version = version;
  }

}

/**
 * An edit object for a counter.
 */
export class CounterEdit extends CounterVersionableKey {

    /**
     * Holds the name of the counter.
     */
    name: string;

    /**
     * Holds the current value of the counter.
     */
    value: number;

    /**
     * Holds the command owning this counter.
     */
    owner: IdentifiedCommand;

    /**
     * The constructor.
     *
     * @param id      The id.
     * @param version The version.
     * @param name    The name.
     * @param value   The current value.
     * @param owner   The command owning this counter.
     */
    constructor(id: number, version: number, name: string, value: number, owner: IdentifiedCommand) {
        super(id, version);

        this.name = name;
        this.value = value;
        this.owner = owner;
    }

    /**
     * Returns a copy of the provided counter.
     *
     * @param counter The counter to copy.
     * @returns A copy of the provided counter.
     */
    public static copy(counter: CounterEdit): CounterEdit {
      return new CounterEdit(counter.id, counter.version, counter.name, counter.value, counter.owner);
    }
}

/**
 * The identified entity for counters.
 */
export interface IdentifiedCounter extends IdentifiedEntity {

}

/**
 * The versionable identified entity for counters.
 */
export interface IdentifiedVersionableCounter extends IdentifiedCounter, Versionable {

}
