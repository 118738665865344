<h1>Befehle</h1>
<div *ngIf="wrongVersion" class="error-message">
  Die Version von dem Befehl war leider nicht korrekt.<br>
  Bitte versuch es erneut.
</div>
<form name="commandsForm" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
  <table class="command-table" style="width: 100%;">
    <tr>
      <td colspan="5" class="sub-th command-table-cell">
        <div class="row">
          <div class="column">Eigene Befehle</div>
          <div class="column">
            <img (click)="createCommand()" class="button-img" style="float: right;" alt="create" [src]="addImg">
          </div>
        </div>
      </td>
    </tr>
    <tr *ngIf="ownedCommands.length > 0">
      <th id="first-column" class="command-table-header">Name</th>
      <th id="second-column" class="command-table-header">Text</th>
      <th class="command-table-header" style="width: 150px;">Zähler</th>
      <th class="command-table-header" style="padding: 0 10px 0 10px">Öffentlich</th>
      <th class="command-table-header"></th>
    </tr>
    <tr *ngFor='let command of ownedCommands'>
      <td class="command-table-cell">
        <div class="command-output">{{command.name}}</div>
      </td>
      <td class="command-table-cell">
        <div class="command-output">{{command.text}}</div>
      </td>
      <td class="command-table-cell">
        <div *ngFor="let counter of command.counters" class="command-output">
          {{counter.name + ": " + counter.value}}
        </div>
      </td>
      <td class="button-cell command-table-cell">
        <input type="checkbox" [defaultChecked]="command.public" (change)="publicChanged($event, command)">
      </td>
      <td class="button-cell command-table-cell">
        <img [src]="editImg" (click)="editCommand(command)" *ngIf="!isDeletingCommand(command)" class="button-img-centered" alt="edit">
        <img [src]="deleteImg" (click)="delete(command)" *ngIf="!isDeletingCommand(command) && !commandHasChanged(command) && command.id != -1" class="button-img-centered" alt="delete">
        <img [src]="deletingImg" *ngIf="isDeletingCommand(command)" class="button-img-centered" alt="deleting">
      </td>
    </tr>
  </table>
  <not-owned-commands [fromUser]="true"  (removeRequest)="removeCommand($event)"></not-owned-commands>
</form>
