
<h1 *ngIf="isForCreate">Befehl Erstellen</h1>
<h1 *ngIf="!isForCreate">Befehl Bearbeiten</h1>
<div *ngIf="hasError" class="error-message" [innerHTML]="errorMessage">
    {{errorMessage}}
</div>
<form (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
    <img *ngIf="loadingCommand" [src]="loadingCommandImg" class="loading-img-centered" alt="Loading Command">
    <table *ngIf="!loadingCommand">
        <tr>
            <td>
                <label for="commandName"><b>Name:</b></label>
            </td>
            <td *ngIf="command != null">
                <input type="text" name="commandName" #commandName="ngModel" notForbiddenName (startingExclamMark)="setStartsWithExclamationMark($event)"
                    [(ngModel)]="command.name" [ngClass]="{ 'has-error': !commandName.valid }" style="min-width: 300px;" required>
                <div *ngIf="startsWithExclamationMark" class="note" style="padding-top: 5px;" >
                    Der Name darf nie mit einem ! beginnen.<span *ngIf="hasChannel()"> Bei denen für deinen Kanal wird dies automathisch hinzugefügt.</span>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <label for="commandText"><b>Text:</b></label>
            </td>
            <td *ngIf="command != null">
                <div>
                    <input type="text" name="commandText" #text="ngModel" [(ngModel)]="command.text" [ngClass]="{ 'has-error': !text.valid }" style="min-width: 300px;" required>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <label for="commandPublic"><b>Öffentlich:</b></label>
            </td>
            <td *ngIf="command != null">
                <input type="checkbox" name="commandPublic" [defaultChecked]="command.public"(change)="publicChanged($event, command)">
            </td>
        </tr>
        <tr>
            <td style="vertical-align: top;">
                <label><b>Counters:</b></label>
            </td>
            <td *ngIf="command != null">
                <counter-edit [command]="command" (saveButtonDisabled)="setSaveButtonDisabled($event)" (counterChanged)="setCounterChanged($event)"></counter-edit>
            </td>
        </tr>
        <tr>
            <td>
                <button (click)="save()" [disabled]="saveButtonDisabled || !f.form.valid || !commandHasChanged()" style="margin-top: 15px;">Speichern</button>
            </td>
            <td>
                <button *ngIf="routeURL != null" (click)="back()" style="margin-top: 15px;">{{commandHasChanged() ? "Abbrechen" : "Zurück"}}</button>
            </td>
        </tr>
    </table>
</form>
