import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, GuardResult, MaybeAsync, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';

/**
 * An active to check if the user already has a channel.
 */
@Injectable({
  providedIn: 'root'
})
export class DoubleChannelActive implements CanActivate {

  /**
   * The constructor.
   * 
   * @param authenticationService The authentication service.
   * @param router                The router.
   */
  constructor(private authenticationService: AuthenticationService, private router: Router) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean {
    let hasChannel = this.authenticationService.getUser().channel != null;
    if (hasChannel) {
      this.router.navigate(['channel']);
    }
    return true;
  }

}
