
/**
 * An abstract class that can be used by standalone components.
 */
export abstract class AbstractStandaloneComponent {

    /**
     * Returns the value of the provided event.
     * 
     * @param $event The event.
     * @returns The value of the provided event.
     */
    getCurrentValue($event: Event) {
        return ($event.target as HTMLInputElement).value;
    }

}