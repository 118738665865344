import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, GuardResult, MaybeAsync, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';

/**
 * An active to check if the user is already logged in when he tries to go to the login page.
 */
@Injectable({
  providedIn: 'root'
})
export class DoubleLoginActive implements CanActivate {

  /**
   * The constructor.
   * 
   * @param authenticationService The authentication service.
   * @param router                The router.
   */
  constructor(private authenticationService: AuthenticationService, private router: Router) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean {
    let loggedIn = this.authenticationService.isLoggedIn();
    if (loggedIn) {
      this.router.navigate(['profile']);
    }
    return true;
  }

}
