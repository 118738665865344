/**
 * An exception that is thrown when a failure in the authentication occurred.
 */
export class AuthenticationException implements Error {

    name: string = "AuthenticationException";
    message: string;
    stack?: string;

    /**
     * The constructor.
     *
     * @param message The message.
     * @param stack   The stack.
     */
    constructor(message: string, stack?: string) {
        this.message = message;
        this.stack = stack;
    }

}

/**
 * An exception that is thrown when a failure in the authentication occurred that should never happen.
 */
export class InternalErrorException implements Error {

    name: string = "InternalErrorException";
    message: string;
    stack?: string;

    /**
     * The constructor.
     *
     * @param message The message.
     * @param stack   The stack.
     */
    constructor(message: string, stack?: string) {
        this.message = message;
        this.stack = stack;
    }

}
