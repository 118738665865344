<h1>Login</h1>
<form name="loginForm" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
  <table class="login-table">
    <tr>
      <td [ngClass]="{ 'has-error': f.submitted && !username.valid }" style="width: 50px;">
        <div>
          <label for="username" class="login-label">Username</label>
        </div>
      </td>
      <td>
        <input type="text" name="username" [(ngModel)]="loginData.username" #username="ngModel" required />
      </td>
    </tr>
    <tr>  
      <td [ngClass]="{ 'has-error': f.submitted && !password.valid }" style="width: 50px;">
        <div>
          <label for="password" class="login-label">Password</label>
        </div>
      </td>  
      <td>
        <input type="password" name="password" [(ngModel)]="loginData.password" #password="ngModel" required />
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <button (click)="login()" [disabled]="!f.form.valid" style="margin-top: 5px;">Login</button>
      </td>
      <td></td>
    </tr>
    <tr>
      <td colspan="2">
        <div name="message" [(ngModel)]="loginData.message" #message="ngModel" style="white-space: pre;">
          {{message.value}}
        </div>
      </td>
    </tr>
  </table>
</form>
