<h1>Spotify</h1>
Zunächst: Wenn du dieses Feature benutzen willst, dann musst du hier dieselbe E-Mail-Adresse benutzen, wie bei deinem Spotify Account.
Diese benötige ich, weil ich sie bei den Benutzern für die Spotify Api eintragen muss.<br>
<b>Das ändert sich,</b> sobald dieses Projekt tatsächlich released wird. Dann werde ich auch den development mode bei Spotify deaktivieren.<br>
<br>
Um dieses Feature zu benutzen, kannst du es - sobald du dich registriert hast - unter 'Einstellungen' aktivieren.
Dann werde ich deine E-Mail-Adresse bei Spotify eintragen, was maximal 24 Stunden dauern sollte.<br>
Danach musst du dich noch unter 'Profile' dabei Anmelden. Dabei wirst du zu einer Seite von Spotify weitergeleitet, auf welcher du dich anmelden musst,
um zu bestätigen, dass du es wirklich erlauben willst, dass ich die unten aufgeführten Sachen tun darf.<br>
<br>
<b>Dabei kann ich dein Passwort nicht auslesen!</b> Es ist, wie gesagt, eine Seite von Spotify, was ihr an der URL erkennen könnt.<br>
<br>
Bisher sind folgende Befehle vorhanden:
<ul>
  <li>
    Abrufen von dem momentan gehörten Song.
  </li>
  <li>
    Abrufen von der/dem momentan gehörten Playlist/Album/Artist:in.
  </li>
</ul>
Ich habe derzeit keinen Zugriff auf irgendetwas anderes, als deinen momentan abgespielten Song bei Spotify.<br>
Sollte sich das ändern, werde ich dich darüber per E-Mail informieren und dich an dem Tag der Veröffentlichung automatisch beim Spotify Feature abmelden.
Wenn du dich dann neu anmeldest, dann stimmst du den neuen Funktionen zu.<br>
<br>
Weitere geplante möglichkeiten siehst du unter <a class="default-link" routerLink="/whats-next" target="_blank">Was kommt noch?</a><br>
Wenn dir irgendwas einfällt, was du hier gerne dabei hättest, dann schreibe gerne eine E-Mail an features&#64;pinkpanth3r.de.
