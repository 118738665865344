import { Component, OnInit } from '@angular/core';
import { ChannelEdit } from './channel';
import { AuthenticationService } from '../authentication/authentication.service';
import { AuthenticationException } from '../generic/errors';
import { CommandEdit } from '../command/command';
import { ChannelService } from './channel.service';
import { UserEdit } from '../user/user';
import { Router } from '@angular/router';
import { MAGNIFIER_IMG, REMOVE_IMG } from '../generic/img-pathes';

/**
 * The component for the channel.
 */
@Component({
  selector: 'channel',
  styleUrl: './channel.component.css',
  templateUrl: './channel.component.html',
})
export class ChannelComponent implements OnInit {

  /**
   * Holds the path to the remove image.
   */
  removeImg: string = REMOVE_IMG;

  /**
   * Holds the path to the magnifier image.
   */
  magnifierImg: string = MAGNIFIER_IMG;

  /**
   * Holds the channel to display.
   */
  channel: ChannelEdit;

  /**
   * The constructor.
   *
   * @param authenticationService The authentication service.
   * @param channelService        The chanel service.
   * @param router                The router.
   */
  constructor(private authenticationService: AuthenticationService, private channelService: ChannelService, private router: Router) {

  }

  ngOnInit(): void {
    let user = this.authenticationService.getUser();
    if (user == null) {
      throw new AuthenticationException("No user is logged in while someone was able to go to the channel page");
    }
    let channel = user.channel;
    if (channel == null) {
      throw new AuthenticationException("The logged in user (" + user.id + ") doesn't have a channel but was able to go to the channel page");
    }
    this.channel = channel;
    this.authenticationService.subscribeToUserChange((u: UserEdit) => {
      this.channel = u.channel;
    });
  }

  /**
   * Get called when the button for adding a command was clicked.
   */
  addCommand() {
    this.router.navigate(["commands/search"], {queryParams: {routeURL: 'channel', forChannel: true}});
  }

  /**
   * Removes the provided command from the channel.
   * 
   * @param command The command.
   */
  removeCommand(command: CommandEdit) {
    this.channelService.removeCommand(command);
  }
}

