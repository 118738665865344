import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";


/**
 * An active to check if the user is logged in. If not he will be redirected to the login page.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginActive implements CanActivate {

  /**
   * The constructor.
   * 
   * @param authenticationService The authentication service.
   * @param router                The router.
   */
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean {
    let loggedIn = this.authenticationService.isLoggedIn();
    if (!loggedIn) {
      this.router.navigate(['login']);
    }
    return true;
  }

}
