<h1>Einstellungen</h1>
<div *ngIf="hasError" class="error-message">
  {{errorMessage}}
</div>
<form name="settingsForm" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
    <table>
        <tr>
            <td style="width: 50px;">
                <div>
                    <label for="username"><b>Username:</b></label>
                </div>
            </td>
            <td style="width: 175px;">
                <input type="text" name="username" [(ngModel)]="settingsUser.username" #username="ngModel" style="width: 100%;" required />
            </td>
        </tr>
        <tr>
            <td>
                <label for="email"><b>E-Mail:</b></label>
            </td>
            <td>
                <input type="text" name="email" [(ngModel)]="settingsUser.email" #email="ngModel" style="width: 100%;" required />
            </td>
        </tr>
        <tr>
            <td>
                <label for="token"><b>Token:</b></label>
            </td>
            <td>
                {{displayedToken}}
            </td>
            <td>
                <div *ngIf="!generatingToken" style="padding-left: 10px;">
                    <button (click)="displayToken()" *ngIf="!isTokenDisplayed">Anzeigen</button>
                    <button (click)="hideToken()" *ngIf="isTokenDisplayed">Verbergen</button>
                    <button (click)="generateToken()" style="width: 150px; margin-left: 10px;">Neu generieren</button>
                </div>
                <img [src]="generatingTokenGif" *ngIf="generatingToken" style="max-width: 20px;" alt="Generating Token">
            </td>
        </tr>
        <tr>
            <td>
                <label for="language"><b>Sprache:</b></label>
            </td>
            <td>
                <select name="language" [(ngModel)]="selectedLanguage" (change)="languageChanged()" style="width: 100px;">
                    <option value="" disabled>Wähle eine Sprache</option>
                    <option *ngFor="let c of languages()" [ngValue]="c">{{ c }}</option>
                </select>
            </td>
        </tr>
        <tr>
            <td colspan="3" class="note">
                Dies ändert noch nichts an der verwendeten Sprache hier auf der Homepage.
            </td>
        </tr>
        <tr>
            <td style="vertical-align: top;">
                <label for="feature"><b>Features:</b></label>
            </td>
            <td>
                <div *ngFor="let ft of featureTypes">
                    <input type="checkbox" [defaultChecked]="isFeatureActive(ft)" (change)="featuresChanged($event, ft)">{{ft}}
                </div>
            </td>
        </tr>
    </table>
    <button *ngIf="!saving" (click)="save()" [disabled]="!f.form.valid || !userHasChanged()">Speichern</button>
    <img *ngIf="saving" style="max-width: 30px; margin-top: 10px;" alt="saving" [src]="savingGif">
</form>
