import { Component } from '@angular/core';

/**
 * The component for what is next.
 */
@Component({
  selector: 'whats-next-desc',
  standalone: true,
  imports: [],
  templateUrl: './whats-next.component.html',
  styleUrl: './whats-next.component.css',
})
export class WhatsNextComponent { }
