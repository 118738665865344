import { HttpClient } from "@angular/common/http";
import { CommandEdit, CommandKey } from "../command/command";
import { CHANNEL_URL } from "../generic/backend-urls";
import { AuthenticationService } from "../authentication/authentication.service";
import { Router } from "@angular/router";
import { DefaultUserResponseHandler } from "../user/user-response";
import { Injectable } from "@angular/core";

/**
 * A service for the channels.
 */
@Injectable({
    providedIn: 'root'
})
export class ChannelService {

  /**
   * The constructor.
   *
   * @param http                  The http client.
   * @param authenticationService The authentication service.
   * @param router                The router.
   */
  constructor(private http: HttpClient, private authenticationService: AuthenticationService, private router: Router) { }
    
  /**
   * Adds the provided command to the channel.
   *
   * @param command The command.
   */
  addCommand(command: CommandEdit) {
    const commandKey = new CommandKey(command.id);
    this.http.post(CHANNEL_URL + "/addCommand", commandKey)
      .subscribe(new DefaultUserResponseHandler(this.authenticationService, this.createErrorMessage(command)));
  }


  /**
   * Removes the provided command from the channel of the user.
   *
   * @param command The command.
   */
  removeCommand(command: CommandEdit) {
    const commandKey = new CommandKey(command.id);
    this.http.post(CHANNEL_URL + "/removeCommand", commandKey)
      .subscribe(new DefaultUserResponseHandler(this.authenticationService, this.createErrorMessage(command)));
  }

  
  /**
   * Creates an error message for the provided command.
   *
   * @param command The command.
   * @returns An error message.
   */
  private createErrorMessage(command: CommandEdit): string {
    return "An error occurred for user " + this.authenticationService.getUser().id + " while trying to remove the command " + command.id;
  }
}