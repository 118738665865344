<h1>Kanal Erstellen</h1>
<span style="font-weight: bold;">
    Wenn du einen Kanal erstellst, dann gehe ich davon aus, dass du den Bot nutzen willst.<br>
    Ansonsten macht es keinen Sinn, deinen Kanal hier hinzuzufügen.<br>
    Um diesen zu nutzen, musst du {{botName}} in deinem Kanal als Moderator hinzuzufügen.
</span>
<form name="createChannelForm" (ngSubmit)="f.form.valid" #f="ngForm" style="margin-top: 10px;" novalidate>
    <table>
        <tr>
            <td>
                <label for="channelName"><b>Name:</b></label>
            </td>
            <td>
                <div>
                    <input type="text" name="channelName" [(ngModel)]="channelName" required>
                </div>
            </td>
        </tr>
    </table>
    <button (click)="save()" [disabled]="!f.form.valid" style="margin-top: 10px;">Save</button>
</form>
