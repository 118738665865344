import { Observer } from "rxjs";
import { UserEdit } from "./user";
import { AuthenticationService } from "../authentication/authentication.service";
import { Router } from "@angular/router";
import { InternalErrorException } from "../generic/errors";
import { HttpErrorResponse } from "@angular/common/http";

/**
 * A response for a user request.
 */
export interface UserResponse {

    /**
     * Holds the token.
     */
    token?: string;

    /**
     * Holds the user.
     */
    user: UserEdit;
}

/**
 * The response received when changing something on a user.
 */
export class UserEditResponse implements UserResponse {

    token: string;

    user: UserEdit;
}

/**
 * The observer handling the response from a request returning a user.
 * !!!It never supports a token for the user!!!
 */
export class DefaultUserResponseHandler implements Observer<UserEdit> {

  /**
   * The URL for the router to go to when everything worked well.
   */
  private routeURL: string;

    /**
     * The constructor.
     *
     * @param authenticationService The authentication service.
     * @param errorMessage          The message to add to the error when something happens.
     * @param router                The router.
     * @param routeURL              The URL for the router to go to when everything worked well. If you don't provide this but a router it will redirect to '/'.
     * @param setWrongVersion       A setter to use if the requested item had a wrong version.
     */
    constructor(private authenticationService: AuthenticationService,
                private errorMessage: string,
                private router?: Router,
                routeURL?: string,
                private setWrongVersion?: (wrongVersion: boolean) => void) {
      if (router == null && routeURL != null) {
          throw new InternalErrorException("The routeURL " + routeURL + " was provided without the router");
      }

      if (routeURL != null) {
        this.routeURL = routeURL;
      } else if (router != null) {
        this.routeURL = "/";
      }
    }

    next(user: UserEdit) {
      this.authenticationService.refreshUser(new DefaultUserResponse(user));
      if (this.setWrongVersion != null) {
        this.setWrongVersion.call(this, false);
      }
      if (this.router != null) {
        this.router.navigate([this.routeURL]);
      }
    }

    error(err: HttpErrorResponse) {
      let status = Number(err.status);
      if (status == 406) {
        this.authenticationService.reloadUser();
        if (this.setWrongVersion != null) {
          this.setWrongVersion.call(this, true);
        }
        return;
      }
      console.error(this.errorMessage, err);
      throw new InternalErrorException(this.errorMessage, err.message)
    }

    complete() {}

}

/**
 * The response received when doing anything returning a user.
 * !!!It never supports a token for the user!!!
 */
export class DefaultUserResponse implements UserResponse {

    /**
     * Holds the user.
     */
    user: UserEdit;

    /**
     * The constructor.
     *
     * @param user The user.
     */
    constructor(user: UserEdit) {
        this.user = user;
    }
}
