import { Injectable} from '@angular/core';
import { UserEdit } from '../user/user';
import { DefaultUserResponseHandler, UserResponse } from '../user/user-response';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AUTHENTICATION_URL } from '../generic/backend-urls';
import { InternalErrorException } from '../generic/errors';

/**
 * A service for the authentication.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  /**
   * Holds the subject for changes on the logged-in user.
   */
  private userChangedSub = new Subject<UserEdit>();


  /**
   * Holds the observable of the subject for changes on the logged-in user.
   */
  private userChangedSubObs: Observable<UserEdit>;

  /**
   * The constructor.
   *
   * @param http   The http client.
   */
  constructor(private http: HttpClient) {
    this.userChangedSubObs = this.userChangedSub.asObservable();
   }

   /**
    * Adds the provided listener to changes on the user.
    *
    * @param listener The listener to add to changes on the user.
    */
  subscribeToUserChange(listener: (data: UserEdit) => void) {
    this.userChangedSubObs.subscribe(listener);
  }

  /**
   * Returns the currently logged in user.
   *
   * @returns The user.
   */
  getUser(): UserEdit {
    return JSON.parse(localStorage.getItem('user'))
  }

  /**
   * Refreshes the currently logged-in user with the provided response.
   *
   * @param userResponse The response for the refresh.
   */
  refreshUser(userResponse: UserResponse): void {
    let user = userResponse.user;
    localStorage.setItem("user", JSON.stringify(user));
    if (userResponse.token != null) {
      localStorage.setItem("token", userResponse.token);
    }

    this.userChangedSub.next(user);

  }

  /**
   * Returns a promise with a token generated for a user.
   *
   * @return The promise with a token generated for a user.
   */
  generateToken(): Promise<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return firstValueFrom(this.http.post<string>(AUTHENTICATION_URL + "/generateToken", {}, { headers, responseType: 'text' as 'json' }))
  }

  /**
   * Sets the currently logged in user from the provided user response.
   *
   * @param userResponse The user response.
   */
  setLoggedIn(userResponse: UserResponse): void {
    let token = userResponse.token;
    let user = userResponse.user;
    if (token == null) {
      throw new InternalErrorException("The userResponse should contain a token");
    }
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
    this.userChangedSub.next(user);
  }

  /**
   * Logs out the currently logged in user.
   */
  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  /**
   * Returns if a user is currently logged in.
   *
   * @returns true if a user is logged in, false otherwise.
   */
  isLoggedIn(): boolean {
    let token =  localStorage.getItem('token');
    let user = localStorage.getItem('user');
    if (token == null && user == null) {
      return false;
    } else if (token == null || user == null) {
      this.logout();
      return false;
    }
    return  true;
  }

  /**
   * Reloads the user.
   */
  reloadUser(): void {
    this.http.post<UserEdit>(AUTHENTICATION_URL + '/findUser', {})
      .subscribe(new DefaultUserResponseHandler(this, "An error occurred while trying to reload the user"));
  }

}
